import React, { useState } from 'react';
import { jsPDF } from 'jspdf';

const SalarySlipGenerator = () => {
  // State to store salary components and employee info
  const [employeeName, setEmployeeName] = useState('');
  const [basicSalary, setBasicSalary] = useState('');
  const [allowances, setAllowances] = useState('');
  const [deductions, setDeductions] = useState('');
  const [netSalary, setNetSalary] = useState(null);

  // Calculate the net salary
  const calculateNetSalary = () => {
    const basic = parseFloat(basicSalary) || 0;
    const allowance = parseFloat(allowances) || 0;
    const deduction = parseFloat(deductions) || 0;

    const net = basic + allowance - deduction;
    setNetSalary(net.toFixed(2));
  };

  // Generate and download the salary slip as a PDF
  const generateSalarySlip = () => {
    const doc = new jsPDF();

    // Add Title
    doc.setFontSize(18);
    doc.text('Salary Slip', 105, 20, null, null, 'center');

    // Add Employee Info
    doc.setFontSize(12);
    doc.text(`Employee Name: ${employeeName}`, 20, 40);
    doc.text(`Basic Salary: ₹${basicSalary}`, 20, 50);
    doc.text(`Allowances: ₹${allowances}`, 20, 60);
    doc.text(`Deductions: ₹${deductions}`, 20, 70);
    doc.text(`Net Salary: ₹${netSalary}`, 20, 80);

    // Save the PDF file
    doc.save(`${employeeName}-salary-slip.pdf`);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h2>Salary Slip Generator</h2>

      {/* Employee Info Inputs */}
      <div>
        <label>
          Employee Name:
          <input
            type="text"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            placeholder="Enter employee name"
            style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
          />
        </label>
      </div>

      <div>
        <label>
          Basic Salary (₹):
          <input
            type="number"
            value={basicSalary}
            onChange={(e) => setBasicSalary(e.target.value)}
            placeholder="Enter basic salary"
            style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
          />
        </label>
      </div>

      <div>
        <label>
          Allowances (₹):
          <input
            type="number"
            value={allowances}
            onChange={(e) => setAllowances(e.target.value)}
            placeholder="Enter allowances"
            style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
          />
        </label>
      </div>

      <div>
        <label>
          Deductions (₹):
          <input
            type="number"
            value={deductions}
            onChange={(e) => setDeductions(e.target.value)}
            placeholder="Enter deductions"
            style={{ width: '100%', padding: '8px', marginBottom: '20px' }}
          />
        </label>
      </div>

      {/* Net Salary Calculation Button */}
      <div>
        <button
          onClick={calculateNetSalary}
          style={{
            padding: '10px 20px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginBottom: '20px',
          }}
        >
          Calculate Net Salary
        </button>
      </div>

      {netSalary !== null && (
        <div>
          <h3>Net Salary: ₹{netSalary}</h3>
        </div>
      )}

      {/* Generate and Download PDF Button */}
      <div>
        <button
          onClick={generateSalarySlip}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007BFF',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Download Salary Slip as PDF
        </button>
      </div>
    </div>
  );
};

export default SalarySlipGenerator;