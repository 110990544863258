import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Container, Button, Form as BootstrapForm } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";

const CandidateForgotPassword = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const initialValues = {
    email: "",
    otp: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    otp: otpSent ? Yup.string().required("OTP is required") : Yup.string(),
    newPassword: otpSent
      ? Yup.string()
          .min(8, "Password must be at least 8 characters")
          .required("Password is required")
      : Yup.string(),
    confirmNewPassword: otpSent
      ? Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
          .required("Confirm password is required")
      : Yup.string(),
  });

  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost ? 'http://localhost:5000/api' : 'https://www.mayuhrsolutions.com/api';
  const handleSubmit = async (values) => {
    try {
      if (!otpSent) {
        // Send OTP request
        const response = await fetch(`${apiUrl}/forgot-password`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },

          body: JSON.stringify({ email: values.email }),
        });

        if (response.ok) {
          setOtpSent(true);
          Swal.fire("OTP Sent", "Check your email for the OTP.", "success");
        } else {
          Swal.fire("Error", "Failed to send OTP.", "error");
        }
      } else {
        const response = await fetch(`${apiUrl}/reset-password`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          Swal.fire("Success", "Password reset successfully.", "success");
        } else {
          Swal.fire("Error", "Failed to reset password.", "error");
        }
      }
    } catch (error) {
      Swal.fire("Error", "An unexpected error occurred.", "error");
    }
  };

  return (
    <Container fluid className="background-image-container">
    <div
      className="d-flex justify-content-center align-items-center vh-100"
      style={{ maxWidth: "400px" }}
    >
      <div className=" bg-white border p-4 rounded shadow-sm w-100">
        <h2 className="text-center mb-4"style={{color:"#f8701b"}}>Forgot Password</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <BootstrapForm.Group className="mb-3">
                <BootstrapForm.Label>
                  Email<span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="email" type="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </BootstrapForm.Group>

              {otpSent && (
                <>
                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>OTP</BootstrapForm.Label>
                    <Field name="otp" className="form-control" />
                    <ErrorMessage
                      name="otp"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>
                      New Password<span className="text-danger">*</span>
                    </BootstrapForm.Label>
                    <div className="d-flex align-items-center">
                      <Field
                        name="newPassword"
                        type={viewPassword ? "text" : "password"}
                        className="form-control"
                      />
                      <Button
                        variant="outline-secondary"
                        className="ms-2"
                        onClick={() => setViewPassword(!viewPassword)}
                      >
                        {viewPassword ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </div>
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>
                      Confirm New Password<span className="text-danger">*</span>
                    </BootstrapForm.Label>
                    <div className="d-flex align-items-center">
                      <Field
                        name="confirmNewPassword"
                        type={viewConfirmPassword ? "text" : "password"}
                        className="form-control"
                      />
                      <Button
                        variant="outline-secondary"
                        className="ms-2"
                        onClick={() =>
                          setViewConfirmPassword(!viewConfirmPassword)
                        }
                      >
                        {viewConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </div>
                    <ErrorMessage
                      name="confirmNewPassword"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </>
              )}

              <Button type="submit" className="w-100" style={{backgroundColor:"#f8701b"}}>
                {otpSent ? "Set New Password" : "Send OTP"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
    </Container>
  );
};

export default CandidateForgotPassword;
