import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import {
  FaUserTie,
  FaUserGraduate,
  FaUser,
  FaArrowRight,
} from "react-icons/fa";
// import './HomePage.css';

function HomePage() {
  return (
    <div className="home-page">
      <section className="section section-1">
        <Container className="hero-section text-center">
          <h1 className="mt-5 hero-title" style={{color:"#f8701b"}}>Welcome to Our HR Portal</h1>
          <p className="hero-subtitle">Log in as HR, Candidate, or Employee</p>
          <Row className="mt-2">
            <Col md={12} className="mt-3">
              <h5 className=" ">About Us</h5>
              <p className="hero-subtitle">
                We specialize in a range of digital services including IT
                Students Internship Programs, Web Development, Android App
                Development, CRM Solutions, OTT Platforms, and HR Services. With
                a commitment to innovation, we help businesses and individuals
                adapt and thrive in the rapidly evolving digital landscape. Our
                holistic approach ensures that every solution we deliver is
                efficient, scalable, and designed to foster growth
              </p>
              {/* <p className="text-center">
              <h4>Let's Begin Your Career,
                 With Us</h4>
              <Button href="/career" className="apply-button"style={{backgroundColor:"#f8701b", color:"#fff"}}>
                Apply Now <FaArrowRight className="ms-2" />
              </Button>
            </p> */}
            </Col>
           
          </Row>
          <Row className="justify-content-center mt-5 p-0">
            <Col md={4} lg={2} xs={12} className="mb-3 ">
              <Card className="login-card shadow">
                <Card.Body>
                  <FaUserTie className="me-2 mt-4" size={30} style={{color:"#f8701b"}} />
                  <Card.Title className="mt-4 text-primary">HR Login</Card.Title>
                  <Button
                    style={{backgroundColor:"#f8701b"}}
                    href="/hr-login"
                    className="w-100 mt-3"
                  >
                    Login
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md={4} lg={2} xs={12} className="mb-3">
              <Card className="login-card shadow">
                <Card.Body>
                  <FaUserGraduate className="me-2 mt-4" size={30} style={{color:"#f8701b"}} />
                  <Card.Title className="mt-4 text-primary">Candidate Login</Card.Title>
                  <Button
                    style={{backgroundColor:"#f8701b"}}
                    href="/login"
                    className="w-100 mt-3"
                  >
                    Login
                  </Button>
                </Card.Body>
              </Card>
            </Col> */}
            <Col md={3} lg={2} xs={12} className="mb-3">
              <Card className="login-card shadow">
                <Card.Body>
                  <FaUser className="me-2 mt-4" size={30} style={{color:"#f8701b"}} />
                  <Card.Title className="mt-4 text-primary">Employee Login</Card.Title>
                  <Button
                    style={{backgroundColor:"#f8701b"}}
                    href="/employee-login"
                    className="w-100 mt-3"
                  >
                    Login
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default HomePage;
