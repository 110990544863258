import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Table,
  Button,
  Col,
  Form,
  Row,
  Container,
  Breadcrumb,
  Pagination,
} from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const PerformanceReport = () => {
    const navigate = useNavigate();
    const [performanceData, setPerformanceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState("");
  
    const apiUrl = window.location.hostname === "localhost"
    ? "http://localhost:5000/api"
    : window.location.hostname === "3.108.171.148"
    ? "http://3.108.171.148:5000/api"
    : "https://www.mayuhrsolutions.com/api";
  
    // Fetch data when the component mounts
    useEffect(() => {
      axios
        .get(`${apiUrl}/employee-logins`)
        .then((response) => {
          setPerformanceData(response.data);
          setFilteredData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching performance data:", error);
        });
    }, []);
  
    // Handle month selection
    const handleMonthChange = (event) => {
      const selectedMonth = event.target.value;
      setSelectedMonth(selectedMonth);
  
      if (selectedMonth === "") {
        // If no month is selected, show all data
        setFilteredData(performanceData);
      } else {
        // Filter data by selected month
        const filtered = performanceData.filter((entry) => {
          const entryMonth = new Date(entry.date).getMonth() + 1; // Month is zero-based
          return entryMonth === parseInt(selectedMonth);
        });
        setFilteredData(filtered);
      }
    };
  
    return (
      <Container className="border shadow p-4"
      style={{ backgroundColor: "aliceblue" }}>
        <div className="d-flex justify-content-end ">
        <Button
          onClick={() => navigate("/performance-report")}
          style={{ backgroundColor: "#f8701b" }}
        >
          Back to Performance Report
        </Button>
      </div>
        <h4 className="text-center" style={{ color: "#f8701b" }}>Monthly Performance Details</h4>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group controlId="monthFilter">
              <Form.Label>Select Month</Form.Label>
              <Form.Control
                as="select"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                <option value="">All Months</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Employee Name</th>
                  <th>Performance Score</th>
                  {/* <th>Remarks</th> */}
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((entry) => (
                    <tr key={entry.id}>
                      {/* <td>{new Date(entry.date).toLocaleDateString()}</td> */}
                      <td>{entry.loginTime
                          ? moment(entry.loginTime).format("YYYY-MM-DD")
                          : "N/A"}</td>
                      <td>{entry.employeeName}</td>
                      <td>{entry.performanceScore}</td>
                      {/* <td>{entry.remarks}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data available for the selected month.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  };
export default PerformanceReport;
