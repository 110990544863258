import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const SalarySlip = ({ employee }) => {
  const generatePDF = () => {
    const input = document.getElementById('salary-slip');

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
    //   pdf.save(`${employee.name}_SalarySlip.pdf`);
    });
  };

  return (
    <div>
      {/* <h1>Employee Profile: {employee.name}</h1>
      <div id="salary-slip" style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
        <h2>Salary Slip</h2>
        <p><strong>Employee Name:</strong> {employee.name}</p>
        <p><strong>Employee ID:</strong> {employee.id}</p>
        <p><strong>Basic Salary:</strong> {employee.salary.basic}</p>
        <p><strong>HRA:</strong> {employee.salary.hra}</p>
        <p><strong>Allowances:</strong> {employee.salary.allowances}</p>
        <p><strong>Gross Salary:</strong> {employee.salary.gross}</p>
        <p><strong>Net Salary:</strong> {employee.salary.net}</p>
      </div> */}
      <button onClick={generatePDF}>Download Salary Slip</button>
    </div>
  );
};

export default SalarySlip;
