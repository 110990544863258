import React, { useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Table,
  Card,
  Container,
  Pagination,
  Breadcrumb
} from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import "chart.js/auto"; // Auto import chart.js for charts

const PerformanceReport = () => {
  const initialData = [
    {
      id: 1,
      employee_name: "John Doe",
      goals_achieved: 85,
      improvement: 10,
      metrics: 90,
    },
    {
      id: 2,
      employee_name: "Jane Smith",
      goals_achieved: 75,
      improvement: 20,
      metrics: 80,
    },
    {
      id: 3,
      employee_name: "Robert Brown",
      goals_achieved: 90,
      improvement: 5,
      metrics: 95,
    },
    {
      id: 4,
      employee_name: "Emily Davis",
      goals_achieved: 80,
      improvement: 15,
      metrics: 85,
    },
    {
      id: 5,
      employee_name: "Michael Lee",
      goals_achieved: 88,
      improvement: 12,
      metrics: 92,
    },
  ];

  const [performanceData, setPerformanceData] = useState(initialData);
  const [filteredEmployee, setFilteredEmployee] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showDetailed, setShowDetailed] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000/api" // Local development
    : "https://www.mayuhrsolutions.com/api";
    
  const handleApply = () => {
    if (filteredEmployee.trim() === "") {
      setValidationError("Employee name field is required.");
      return;
    }

    const filteredData = initialData.filter((data) =>
      data.employee_name.toLowerCase().includes(filteredEmployee.toLowerCase())
    );
    setPerformanceData(filteredData);
    setFilteredEmployee(""); // Reset filter input
    setSelectedEmployee(null); // Ensure no employee is selected
    setShowDetailed(null); // Ensure no detailed view is shown
    setCurrentPage(1); // Reset to the first page
  };

  const handleCancel = () => {
    setFilteredEmployee("");
    setPerformanceData(initialData);
    setSelectedEmployee(null); // Reset the filter
    setShowDetailed(null); // Ensure no detailed view is shown
    setCurrentPage(1); // Reset to the first page
  };

  const handleShowDetails = (employee) => {
    setShowDetailed(employee.id);
  };

  const handleHideDetails = () => {
    setShowDetailed(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate pie chart data for the selected employee
  const pieData = selectedEmployee
    ? {
        labels: ["Goals Achieved", "Improvement Needed", "Metrics"],
        datasets: [
          {
            label: "Performance Data",
            data: [
              selectedEmployee.goals_achieved,
              selectedEmployee.improvement,
              selectedEmployee.metrics,
            ],
            backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
            hoverBackgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
          },
        ],
      }
    : null;

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = performanceData.slice(indexOfFirstItem, indexOfLastItem);

  // Generate pagination items
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(performanceData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Performance Report</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="text-primary">Performance Report</h1>

      {/* Filter Form */}
      <Card className="p-4 mb-4">
        <Form>
          <Row className="align-items-center">
            <h4>Filter</h4>
            <Col md={6} sm={12}>
              <Form.Group controlId="employeeName">
                <Form.Label>
                  Employee Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter employee name"
                  value={filteredEmployee}
                  onChange={(e) => setFilteredEmployee(e.target.value)}
                  isInvalid={!!validationError}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} sm={12} className="text-md-end mt-2 mt-md-0">
              <Button variant="primary" className="me-2" onClick={handleApply}>
                Search
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      {showDetailed ? (
        <div className="d-flex justify-content-end">
          <Button
            variant="secondary"
            className="mt-3"
            onClick={handleHideDetails}
          >
            Back to Table
          </Button>
        </div>
      ) : (
        <></>
      )}

      {/* Render Pie Chart or Table */}
      {showDetailed ? (
        <Card className="p-4 mb-4 mt-3">
          <h4>Performance Details</h4>
          <div className="d-flex justify-content-center">
            <Pie data={pieData} className="performance-pie-chart small-chart" />
          </div>
        </Card>
      ) : (
        <Card className="p-4">
          <h4 >Employee Performance</h4>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Employee Name</th>
                <th>Total Performance</th>
                <th>Performance Details</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((employee, index) => (
                <tr key={employee.id}>
                  <td>{index + 1 + indexOfFirstItem}</td>
                  <td>{employee.employee_name}</td>
                  <td>
                    {(
                      (employee.goals_achieved +
                        employee.metrics -
                        employee.improvement) /
                      3
                    ).toFixed(2)}
                    %
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setSelectedEmployee(employee);
                        handleShowDetails(employee);
                      }}
                    >
                      Show
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination */}
          <Pagination className="d-flex justify-content-end">
            <Pagination.Prev
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
            />
            {pageNumbers.map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => handlePageChange(number)}
              >
                {number}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                )
              }
            />
          </Pagination>
        </Card>
      )}
    </Container>
  );
};

export default PerformanceReport;
