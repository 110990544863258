import React from "react";
import { Formik, Form, Field, ErrorMessage, } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Button, Container, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const VisitorForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
  const initialValues = {
    clientName: "",
    contactPersonName: "",
    contactPersonMobileNo: "",
    contactPersonEmail: "",
    staffEmployeeId: "",
    staffName: "",
    visitingLocation: "",
    visitorPhoto: null,
  };

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  const validationSchema = Yup.object().shape({
    clientName: Yup.string()
      .required("Client Name is required")
      .max(30, "Minimum 30 characters required"),
    contactPersonName: Yup.string()
      .required("Contact Person Name is required")
      .max(20, "Minimum 20 characters required"),
    contactPersonMobileNo: Yup.string()
      .required("Mobile Number is required")
      .matches(/^[789]\d{9}$/, "Mobile number must start with 7, 8, or 9 and be 10 digits long"),
    contactPersonEmail: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    staffEmployeeId: Yup.string()
      .required("Staff Employee ID is required")
      .min(3, "Minimum 3 characters required"),
    staffName: Yup.string()
      .required("Staff Name is required")
      .max(20, "Minimum 20 characters required"),
    visitingLocation: Yup.string()
      .required("Visiting Location is required")
      .min(3, "Minimum 3 characters required"),
    visitorPhoto: Yup.mixed()
      .required("Visitor Photo is required")
      .test(
        "fileType",
        "Only images are allowed",
        (value) => value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
      ),
  });


  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("clientName", values.clientName);
    formData.append("contactPersonName", values.contactPersonName);
    formData.append("contactPersonMobileNo", values.contactPersonMobileNo);
    formData.append("contactPersonEmail", values.contactPersonEmail);
    formData.append("staffEmployeeId", values.staffEmployeeId);
    formData.append("staffName", values.staffName);
    formData.append("visitingLocation", values.visitingLocation);
    formData.append("visitorPhoto", values.visitorPhoto);

    try {
      const response = await axios.post(`${apiUrl}/add-visitor`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: response.data.message || "Visitor Form submitted successfully.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          resetForm(); // Reset the form after submission
          navigate(`/employee-dashboard/${id}`); // Navigate to dashboard
        });
      } else {
        throw new Error("Failed to submit the form");
      }
    } catch (error) {
      console.error("Error:", error);

      Swal.fire({
        title: "Success!",
        text:  "Visitor Form submitted successfully.",
        icon: "success",
        button: "OK",
      });
    }
  };

  return (
    <Container className="border shadow p-5 mt-4"
    style={{ backgroundColor: "aliceblue" }}>
       
      <h2 className="text-center mb-4" style={{ color: "#f8701b" }}>Visitor Form</h2>
      <div className="d-flex justify-content-end ">
        <Button
          onClick={() => navigate(`/employee-dashboard/${id}`)}
          style={{ backgroundColor: "#f8701b" }}
        >
          Back to Employee Dashboard
        </Button>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, resetForm }) => (
          <Form>
            <Row>
              <h5 style={{color:"#f8701b"}}>Client Information</h5>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="clientName">Client's Name/Organization's Name<span className="text-danger">*</span></label>
                  <Field
                    type="text"
                    name="clientName"
                    id="clientName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="clientName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="contactPersonName">Contact Person (First & Last) Name <span className="text-danger">*</span></label>
                  <Field
                    type="text"
                    name="contactPersonName"
                    id="contactPersonName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="contactPersonName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="contactPersonMobileNo">Contact Person Mobile No.<span className="text-danger">*</span></label>
                  <Field
                    type="text"
                    name="contactPersonMobileNo"
                    id="contactPersonMobileNo"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="contactPersonMobileNo"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="contactPersonEmail">Contact Person Email<span className="text-danger">*</span></label>
                  <Field
                    type="email"
                    name="contactPersonEmail"
                    id="contactPersonEmail"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="contactPersonEmail"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              </Row>
              <Row>
                <h5 style={{color:"#f8701b"}}>Staff/Employee Information</h5>
              <Col md={6}>
              
                <div className="mb-3">
                  <label htmlFor="staffEmployeeId">Staff Employee ID<span className="text-danger">*</span></label>
                  <Field
                    type="text"
                    name="staffEmployeeId"
                    id="staffEmployeeId"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="staffEmployeeId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="staffName">Staff's (First & Last) Name<span className="text-danger">*</span></label>
                  <Field
                    type="text"
                    name="staffName"
                    id="staffName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="staffName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="visitingLocation">Visiting Location<span className="text-danger">*</span></label>
                  <Field
                    type="text"
                    name="visitingLocation"
                    id="visitingLocation"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="visitingLocation"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="visitorPhoto">Visitor Photo<span className="text-danger">*</span></label>
                  <input
                    type="file"
                    name="visitorPhoto"
                    id="visitorPhoto"
                    className="form-control"
                    onChange={(event) => {
                      setFieldValue("visitorPhoto", event.target.files[0]);
                    }}
                  />
                  <ErrorMessage
                    name="visitorPhoto"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col>
            </Row>
            <div className="">
              <Button variant="secondary" className="me-3" onClick={() => resetForm()}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      
    </Container>
  );
};

export default VisitorForm;
