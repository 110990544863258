import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Alert, Card, Table} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const LeaveAppealForm = ({ empId }) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    employeeId: "",
    department: "",
    leaveType: "",
    startDate: "",
    endDate: "",
    reason: "",
    status: "",
    document: null,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
// State for leave details
const [leaveDetails, setLeaveDetails] = useState([]);

const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, document: e.target.files[0] });
  };

    // Fetch leave details initially or after submission
    const fetchLeaveDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/leave-appeal/leave-details/${id}`);
        setLeaveDetails(response.data);
      } catch (error) {
        console.error("Error fetching leave details:", error);
      }
    };

     // Fetch leave details when the component mounts
  useEffect(() => {
    fetchLeaveDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });
  
    try {
      const response = await axios.post(`${apiUrl}/leave-appeal`, formDataToSend, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      // Use SweetAlert for Success
      Swal.fire({
        title: "Success!",
        text: response.data.message || "Your leave appeal has been submitted successfully.",
        icon: "success",
        button: "OK",
      }).then(() => {
        // Refresh the page after clicking "OK"
        window.location.reload();
      });
  
      setFormData({
        name: "",
        employeeId: "",
        department: "",
        leaveType: "",
        startDate: "",
        endDate: "",
        reason: "",
        status: "",
        document: null,
      });
      setSuccessMessage(""); // Clear any previous messages if stored
      setErrorMessage("");
    } catch (error) {
      // Use SweetAlert for Error
      Swal.fire({
        title: "Error!",
        text: error.response?.data?.message || "Error submitting the form. Please try again.",
        icon: "error",
        button: "OK",
      });
  
      setSuccessMessage(""); // Clear any previous messages if stored
      setErrorMessage("Error submitting the form. Please try again.");
    }
  };
  

  return (
    <div className="container mt-5 border p-5" style={{backgroundColor:"aliceblue"}} >
       <div className="d-flex justify-content-end ">
        <Button
          onClick={() => navigate(`/employee-dashboard/${id}`)}
          style={{ backgroundColor: "#f8701b" }}
        >
          Back to Employee Dashboard
        </Button>
      </div>
      <h2 className="text-center" style={{color:"#f8701b"}}>Leave Appeal Form</h2>
    
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Card className="border shadow p-3 mt-3">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Employee Name<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="employeeId" className="mb-3">
              <Form.Label>Employee ID<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                name="employeeId"
                value={formData.employeeId}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="department" className="mb-3">
              <Form.Label>Department<span className="text-danger">*</span></Form.Label>
              <Form.Select
                name="department"
                value={formData.department}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Mayu Sport Academy">Mayu Sport Academy</option>
                <option value="Mayu IT Planet">Mayu IT Planet</option>
                <option value="Mayu E Learning">Mayu E Learning</option>
                <option value="Mayu TV">Mayu TV</option>
                <option value="Mayu E-Governance">Mayu E-Governance</option>
                <option value="Mayu Business Service Group Of India">Mayu Business Service Group Of India</option>

              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="leaveType" className="mb-3">
              <Form.Label>Leave Type<span className="text-danger">*</span></Form.Label>
              <Form.Select
                name="leaveType"
                value={formData.leaveType}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Sick">Sick</option>
                <option value="Casual">Casual</option>
                <option value="Vacation">Vacation</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="startDate" className="mb-3">
              <Form.Label>Start Date<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="endDate" className="mb-3">
              <Form.Label>End Date<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="reason" className="mb-3">
          <Form.Label>Reason for Appeal<span className="text-danger">*</span></Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="reason"
            value={formData.reason}
            onChange={handleChange}
            required
          />
        </Form.Group>
        {/* <Form.Group controlId="document" className="mb-3">
          <Form.Label>Supporting Document</Form.Label>
          <Form.Control type="file" name="document" onChange={handleFileChange} />
        </Form.Group> */}
        <Button variant="primary" type="submit">
          Submit 
        </Button>
      </Form>
      </Card>
      <Card className="border shadow p-4 mt-3">
      {leaveDetails.length > 0 && (
        <div className="mt-5">
          <h3 style={{color:"#f8701b"}}>Applied Leaves</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Leave Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Reason</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {leaveDetails.map((leave, index) => (
                <tr key={index}>
                  <td>{leave.leave_type}</td>
                  <td>{new Date(leave.start_date).toLocaleDateString()}</td>
                  <td>{new Date(leave.end_date).toLocaleDateString()}</td>
                  <td>{leave.reason}</td>
                  <td>{leave.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      </Card>
    </div>
  );
};

export default LeaveAppealForm;
