import React, { useEffect, useState } from "react";
import {
  Table,
  Container,
  Spinner,
  Button,
  Modal,
  Form,
  Col,
  Row,
  Card,
  Breadcrumb,
  Pagination,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const perPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    empId: "",
    department: "",
  });

  const departmentOptions = [
    "Mayu Sport Academy",
    "Mayu IT Planet",
    "Mayu E Learning",
    "Mayu TV",
    "Mayu E-Governance",
    "Mayu Business Service Group Of India",
  ];

  // Calculate the start and end index for slicing employees data based on currentPage
  const indexOfLastEmployee = currentPage * perPage;
  const indexOfFirstEmployee = indexOfLastEmployee - perPage;
  const currentEmployees = employees.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(employees.length / perPage);

  const navigate = useNavigate();
  
  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  // const fetchEmployees = async () => {
  //   try {
  //     const response = await fetch(`${apiUrl}/employees`);
  //     if (!response.ok) throw new Error("Failed to fetch employees");
  //     const data = await response.json();
  //     setEmployees(data);
  //   } catch (error) {
  //     setError(error.message);
  //     Swal.fire({ icon: "error", title: "Error!", text: error.message });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchEmployees = async () => {
    try {
      let query = "";
      if (filters.empId) {
        query += `empId=${filters.empId}&`;
      }
      if (filters.department) {
        query += `department=${filters.department}`;
      }

      const response = await fetch(`${apiUrl}/employees?${query}`);
      if (!response.ok) throw new Error("Failed to fetch employees");
      const data = await response.json();
      setEmployees(data);
    } catch (error) {
      setError(error.message);
      Swal.fire({ icon: "error", title: "Error!", text: error.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
    if (confirmDelete.isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}/employees/${id}`, {
          method: "DELETE",
        });
        if (!response.ok) throw new Error("Failed to delete employee");
        Swal.fire("Deleted!", "Employee has been deleted.", "success");
        fetchEmployees(); // Refetch employees after deletion
      } catch (error) {
        Swal.fire({ icon: "error", title: "Error!", text: error.message });
      }
    }
  };

  const handleEdit = (employee) => {
    setSelectedEmployee(employee); // Set employee to edit
    setShowModal(true); // Show the modal
  };

  const handleEditEmployee = (employee) => {
    navigate(`/employee-profile/${employee.id}`);
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setSelectedEmployee(null); // Reset selected employee
  };

  const handleResetFilters = () => {
    setFilters({
      empId: "",
      department: null,
    });
    fetchEmployees();
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setSelectedEmployee((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateEmployee = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/employees/${selectedEmployee.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(selectedEmployee),
        }
      );

      if (!response.ok) throw new Error("Failed to update employee");
      Swal.fire("Success!", "Employee details updated.", "success");
      fetchEmployees(); // Refresh employees list
      handleCloseModal(); // Close modal after success
    } catch (error) {
      Swal.fire({ icon: "error", title: "Error!", text: error.message });
    }
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <div
        className="border shadow p-4"
        style={{ backgroundColor: "aliceblue" }}
      >
        <h1 className="registration-heading mt-3">Employee List</h1>
        <div className="d-flex justify-content-end ">
          <Button
            onClick={() => navigate("/hr-dashboard")}
            style={{ backgroundColor: "#f8701b" }}
          >
            Back to HR Dashboard
          </Button>
        </div>
        <Breadcrumb>
          <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Employee Info</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="border shadow p-3 mt-3">
          <Row className="mt-3">
            <Col sm={12} md={4} lg={4}>
              {/* Filter Inputs */}
              <Form.Group controlId="empIdFilter">
                <Form.Label>Filter by Emp ID</Form.Label>
                <Form.Control
                  type="text"
                  id="empId"
                  value={filters.empId}
                  onChange={(e) =>
                    setFilters((prev) => ({ ...prev, empId: e.target.value }))
                  }
                  placeholder="Enter Employee ID"
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <Form.Group controlId="departmentFilter">
                <Form.Label>Filter by Department</Form.Label>
                <Form.Control
                  as="select"
                  id="departmentFilter"
                  value={filters.department || ""}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      department: e.target.value,
                    }))
                  }
                >
                  <option value="">Select Department</option>
                  {departmentOptions.map((dept, idx) => (
                    <option key={idx} value={dept}>
                      {dept}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col
              sm={12}
              md={12}
              lg={4}
              className="d-flex align-items-end justify-content-end"
            >
              <Button
                className="me-2"
                style={{ backgroundColor: "#f8701b" }}
                onClick={fetchEmployees}
              >
                Apply Filters
              </Button>
              <Button variant="secondary" onClick={handleResetFilters}>
                Reset Filters
              </Button>
            </Col>
          </Row>
        </Card>
        <div className="table-responsive mt-3">
          {error && <p className="text-danger">{error}</p>}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Employee ID</th>
                <th>Employee Name</th>
                <th>Job Title</th>
                <th>Department</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentEmployees.length > 0 ? (
                currentEmployees.map((employee, index) => (
                  <tr key={employee.id}>
                    <td>{index + 1 + indexOfFirstEmployee}</td>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => handleEdit(employee)}
                      >
                        {employee.empId}
                      </Button>
                    </td>
                    <td>{employee.employeeName}</td>
                    <td>{employee.jobTitle}</td>
                    <td>{employee.department}</td>
                    <td>
                      <Button
                        variant="primary"
                        className="me-2"
                        onClick={() => handleEditEmployee(employee)}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{ backgroundColor: "#f8701b" }}
                        onClick={() => handleDelete(employee.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No employees found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* Pagination Component */}
          <div className="d-flex justify-content-end">
            <Pagination>
              {/* Previous Button */}
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />

              {/* First Page */}
              {currentPage > 2 && (
                <>
                  <Pagination.Item onClick={() => handlePageChange(1)}>
                    1
                  </Pagination.Item>
                  {currentPage > 3 && <Pagination.Ellipsis />}
                </>
              )}

              {/* Current and Surrounding Pages */}
              {[...Array(totalPages)]
                .map((_, index) => index + 1)
                .filter(
                  (page) =>
                    page === currentPage || // Always show the current page
                    page === currentPage - 1 || // One page before
                    page === currentPage + 1 // One page after
                )
                .map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

              {/* Last Page */}
              {currentPage < totalPages - 1 && (
                <>
                  {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
                  <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                  </Pagination.Item>
                </>
              )}

              {/* Next Button */}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </div>
        {/* Modal for Editing Employee */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="lg"
          className="border shadow p-4 mt-5"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#f8701b", textAlign: "center" }}>
              Employee Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedEmployee && (
              <Form>
                <h4 style={{ color: "#f8701b" }}>Personal Details</h4>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formEmployeeName" className="mt-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.employeeName}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            employeeName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formEmail" className="mt-3">
                      <Form.Label>Personal Email</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.email}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            email: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formContactNumber" className="mt-3">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="number"
                        value={selectedEmployee.contactNumber}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            contactNumber: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formGender" className="mt-3">
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.gender}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            gender: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="formEducation" className="mt-3">
                      <Form.Label>Education</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.education}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            education: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formDateOfBirth" className="mt-3">
                      <Form.Label>Date Of Birth</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.dob}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            dob: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formBloodGroup" className="mt-3">
                      <Form.Label>Blood Group</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.bloodGroup}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            bloodGroup: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="formPermanentAddress"
                      className="mt-3"
                    >
                      <Form.Label>Permanent Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.permanentAddress}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            permanentAddress: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="formCurrentAddress" className="mt-3">
                      <Form.Label>Current Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.currentAddress}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            currentAddress: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="formFamilyMemberName"
                      className="mt-3"
                    >
                      <Form.Label>Family Member Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.familyMemberName}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            familyMemberName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formRelation" className="mt-3">
                      <Form.Label>Relation</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.relation}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            relation: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="formEmergencyContactNumber"
                      className="mt-3"
                    >
                      <Form.Label>Emergency Contact Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.emergencyContactNumber}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            emergencyContactNumber: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <h4 style={{ color: "#f8701b" }}>Professional Details</h4>

                <Row className="mt-3">
                  <Col md={4}>
                    <Form.Group controlId="formDepartment" className="mt-3">
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.department}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            department: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formEmpId" className="mt-3">
                      <Form.Label>Employee ID</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.empId}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            empId: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={4}>
                <Form.Group controlId="formTeam"className="mt-3">
                  <Form.Label>Team</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.team}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        team: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col> */}
                  <Col md={4}>
                    <Form.Group controlId="formManager" className="mt-3">
                      <Form.Label>Reporting Manager</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.manager}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            manager: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4}>
                    <Form.Group controlId="formJoiningDate" className="mt-3">
                      <Form.Label>Joining Date</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.joiningDate}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            joiningDate: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formOfficialEmail" className="mt-3">
                      <Form.Label>Official Email</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.officialEmail}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            officialEmail: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formAnnualCTC" className="mt-3">
                      <Form.Label>Annual CTC</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.annualCTC}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            annualCTC: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4}>
                    <Form.Group controlId="formPassword" className="mt-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={selectedEmployee.password}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            password: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formJobTitle" className="mt-3">
                      <Form.Label>Job Title</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.jobTitle}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            jobTitle: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <h4 style={{ color: "#f8701b" }}>Bank Account Details</h4>
                <Row className="mt-3">
                  <Col md={4}>
                    <Form.Group controlId="formAccountNumber" className="mt-3">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.accountNumber}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            accountNumber: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formIfscCode" className="mt-3">
                      <Form.Label>IFSC Code</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.ifscCode}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            ifscCode: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBranchName" className="mt-3">
                      <Form.Label>Branch Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedEmployee.branchName}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            branchName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <Form.Group controlId="formBranchAddress" className="mt-3">
                      <Form.Label>Branch Address</Form.Label>
                      <Form.Control
                        type="textarea"
                        value={selectedEmployee.branchAddress}
                        onChange={(e) =>
                          setSelectedEmployee({
                            ...selectedEmployee,
                            branchAddress: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleUpdateEmployee}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default EmployeeTable;
