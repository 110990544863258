import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container, Button, Spinner } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const EmployeeDetailsPage = () => {
  const { id } = useParams();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/employee/${id}`);
        setEmployee(response.data.employee);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching employee details", error);
        setLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  if (!employee) {
    return (
      <div className="text-center mt-5">
        <h3>No employee found</h3>
        <Button onClick={() => navigate(`/employee-dashboard/${id}`)}>
          Back to List
        </Button>
      </div>
    );
  }

  return (
    <Container
      className="border shadow p-2 mt-4"
      style={{ backgroundColor: "aliceblue" }}
    >
      <h2 className="text-center mb-4" style={{ color: "#f8701b" }}>
        Employee Details
      </h2>
      <div className="d-flex justify-content-end ">
        <Button
          onClick={() => navigate(`/employee-dashboard/${id}`)}
          style={{ backgroundColor: "#f8701b" }}
        >
          Back to Employee Dashboard
        </Button>
      </div>
      <div>
        <Card className="p-4 shadow-lg mb-4 mt-3">
          <h4 style={{ color: "#f8701b" }}>Personal Information</h4>
          <Row className="mt-3">
            <Col md={4}>
              <strong>Name:</strong> {employee.employeeName}
            </Col>
            <Col md={4}>
              <strong>Email:</strong> {employee.email}
            </Col>
            <Col md={4}>
              <strong>Contact:</strong> {employee.contactNumber}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <strong>Gender:</strong> {employee.gender}
            </Col>
            {/* <Col md={4}>
              <strong>Date of Birth:</strong> {formatDate(employee.dob)}
            </Col> */}
            <Col md={4}>
              <strong>Date of Birth:</strong>{" "}
              {employee.dob ? formatDate(employee.dob) : " "}
            </Col>
            <Col md={4}>
              <strong>Blood Group:</strong> {employee.bloodGroup}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <strong>Permanent Address:</strong> {employee.permanentAddress}
            </Col>
            <Col md={4}>
              <strong>Current Address:</strong> {employee.currentAddress}
            </Col>
          </Row>
        </Card>

        <Card className="p-4 shadow-lg mb-4">
          <h4 style={{ color: "#f8701b" }}>Professional Information</h4>
          <Row className="mt-3">
            <Col md={4}>
              <strong>Job Title:</strong> {employee.jobTitle}
            </Col>
            <Col md={4}>
              <strong>Employee ID:</strong> {employee.empId}
            </Col>
            <Col md={4}>
              <strong>Department:</strong> {employee.department}
            </Col>
          </Row>
          <Row className="mt-3">
            {/* <Col md={4}><strong>Team:</strong> {employee.team}</Col> */}
            <Col md={4}>
              <strong>Manager:</strong> {employee.manager}
            </Col>
            {/* <Col md={4}>
              <strong>Joining Date:</strong> {formatDate(employee.joiningDate)}
            </Col> */}
            <Col md={4}>
              <strong>Joining Date:</strong>{" "}
              {employee.joiningDate ? formatDate(employee.joiningDate) : " "}
            </Col>
            <Col md={4}>
              <strong>Education:</strong> {employee.education}
            </Col>
          </Row>
          <Row className="mt-3">
            {/* <Col md={4}>
              <strong>Annual CTC:</strong> ₹{employee.annualCTC}
            </Col> */}
            <Col md={4}>
              <strong>Official Email:</strong> {employee.officialEmail}
            </Col>
          </Row>
        </Card>

        <Card className="p-4 shadow-lg mb-4">
          <h4 style={{ color: "#f8701b" }}>Bank Account Details</h4>
          <Row className="mt-3">
            <Col md={4}>
              <strong>Account Number:</strong> {employee.accountNumber}
            </Col>
            <Col md={4}>
              <strong>IFSC Code:</strong> {employee.ifscCode}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <strong>Branch Name:</strong> {employee.branchName}
            </Col>
            <Col md={8}>
              <strong>Branch Address:</strong> {employee.branchAddress}
            </Col>
          </Row>
        </Card>
      </div>
    </Container>
  );
};

export default EmployeeDetailsPage;
