import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Card, Button, Pagination } from "react-bootstrap"; 
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const LeaveTable = () => {
  const [leaveData, setLeaveData] = useState([]);
  const navigate = useNavigate();
  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(1); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leaveDetails, setLeaveDetails] = useState([]);

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  // Calculate the start and end index for slicing employees data based on currentPage
  const indexOfLastEmployee = currentPage * perPage;
  const indexOfFirstEmployee = indexOfLastEmployee - perPage;
  const currentEmployees = leaveData.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(leaveData.length / perPage);

  // Fetch leave data when component mounts
  useEffect(() => {
    axios
      .get(`${apiUrl}/leave-appeal/leave-details`)
      .then((response) => {
        
        setLeaveData(response.data); // Store the leave data
        setLoading(false); 
      })
      .catch((err) => {
        setError("Error fetching leave details"); // Handle error
        setLoading(false);
      });
  }, []);

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  // Handle leave status update
  const handleStatusChange = (id, newStatus) => {
    axios
      .put(`${apiUrl}/leave-appeal/leave-details/status`, {
        id: id,
        status: newStatus,
      })
      .then((response) => {
        // Update the status locally after successful update
        setLeaveDetails((prevDetails) =>
          prevDetails.map((leave) =>
            leave.id === id ? { ...leave, status: newStatus } : leave
          )
        );
        Swal.fire({
          title: "Success!",
          text: response.data.message || "Status Updated successfully.",
          icon: "success",
          button: "OK",
        }).then(() => {
          // Refresh the page after clicking "OK"
          window.location.reload();
        });
      })
      .catch((error) => {
        console.error("Error updating leave status:", error);
        alert("Error updating leave status");
      });
  };

  return (
    <div
      className="container mt-4 p-4"
      style={{ backgroundColor: "aliceblue" }}
    >
      <div className="d-flex justify-content-end ">
        <Button
          onClick={() => navigate("/hr-dashboard")}
          style={{ backgroundColor: "#f8701b" }}
        >
          Back to HR Dashboard
        </Button>
      </div>
      <h1 className="registration-heading mt-3">Leave Details</h1>
      <Card className="border shadow p-5">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Employee ID</th>
              <th>Department</th>
              <th>Leave Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Reason</th>
              {/* <th>Document</th> */}
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {currentEmployees.map((leave, index) => (
              <tr key={leave.id}>
                <td>{leave.srNo || index + 1 + indexOfFirstEmployee}</td>
                {/* <td>{leave.id}</td> */}
                <td>{leave.name}</td>
                <td>{leave.employee_id}</td>
                <td>{leave.department}</td>
                <td>{leave.leave_type}</td>
                <td>{new Date(leave.start_date).toLocaleDateString()}</td>
                <td>{new Date(leave.end_date).toLocaleDateString()}</td>
                <td>{leave.reason}</td>
                {/* <td>
                  {leave.document_path ? (
                    <a
                      href={leave.document_path}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Document
                    </a>
                  ) : (
                    "No Document"
                  )}
                </td> */}
                <td>
                  {/* Dropdown to change status */}
                  <select
                    value={leave.status}
                    onChange={(e) =>
                      handleStatusChange(leave.id, e.target.value)
                    }
                  >
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Not Approved">Not Approved</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end">
        <Pagination>
  {/* Previous Button */}
  <Pagination.Prev
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  />

  {/* First Page */}
  {currentPage > 2 && (
    <>
      <Pagination.Item onClick={() => handlePageChange(1)}>
        1
      </Pagination.Item>
      {currentPage > 3 && <Pagination.Ellipsis />}
    </>
  )}

  {/* Current and Surrounding Pages */}
  {[...Array(totalPages)]
    .map((_, index) => index + 1)
    .filter(
      (page) =>
        page === currentPage || // Always show the current page
        page === currentPage - 1 || // One page before
        page === currentPage + 1 // One page after
    )
    .map((page) => (
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    ))}

  {/* Last Page */}
  {currentPage < totalPages - 1 && (
    <>
      {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
      <Pagination.Item onClick={() => handlePageChange(totalPages)}>
        {totalPages}
      </Pagination.Item>
    </>
  )}

  {/* Next Button */}
  <Pagination.Next
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  />
</Pagination>
        </div>
      </Card>
    </div>
  );
};

export default LeaveTable;
