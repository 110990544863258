import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Dropdown,
  Modal,
  Pagination,
  Form,
  Breadcrumb,
} from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

const ApplicationsPage = () => {
  const [applications, setApplications] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({});
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(applications.length / itemsPerPage);
  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost ? "http://localhost:5000/api" : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    // Fetch all applications
    axios.get(`${apiUrl}/applications/all`)
      .then(response => {

        setApplications(response.data);

        const initialStatuses = response.data.reduce((acc, app) => {
          acc[app.id] = {
            status: app.status || "Pending",
            reasons: Array.isArray(app.reasons) ? app.reasons : [],
          };
          return acc;
        }, {});
        setCurrentStatus(initialStatuses);
      })
      .catch((error) => console.error("Error fetching applications:", error));
  }, []);

  const statusOptions = [
    "Pending",
    "Shortlisted(HR)",
    "Shortlisted(Manager)",
    "Selected",
    "Rejected",
  ];

  const reasons = [
    "Not qualified",
    "Selected from HR interview",
    "Selected from Manager Round",
    "Final selection",
  ];

  const handleOpenModal = (candidate, newStatus) => {
    setSelectedCandidate(candidate);
    setSelectedReasons(currentStatus[candidate.id]?.reasons || []);
    setCurrentStatus((prev) => ({
      ...prev,
      [candidate.id]: { ...prev[candidate.id], status: newStatus },
    }));
    setShowModal(true);
  };


  const handleSubmit = async () => {
    const candidateId = selectedCandidate.id;
    const updatedStatus = currentStatus[candidateId];

    try {
      await axios.patch(`${apiUrl}/applications/${candidateId}/status`, {
        status: updatedStatus.status,
        reasons: selectedReasons,
      });

      Swal.fire("Success", "Status updated successfully!", "success").then(() => {
        setCurrentStatus((prev) => ({
          ...prev,
          [candidateId]: {
            ...prev[candidateId],
            reasons: selectedReasons,
          },
        }));
        setShowModal(false);
      });
    } catch (error) {
      Swal.fire("Error", "Failed to update status.", "error");
    }
  };

  const handleStatusChange = (candidate, newStatus) => {
    handleOpenModal(candidate, newStatus);
  };

  const generateOfferLetter = async (candidate) => {
    try {
      await axios.post(`${apiUrl}/generate-offer-letter`, {
        candidateName: candidate.name,
        position: "Software Engineer",
        email: candidate.email,
      });

      Swal.fire("Success", "Offer letter generated successfully!", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to generate offer letter.", "error");
    }
  };

  return (
    <Container>
      <h3 className="registration-heading mt-3">Applications for Jobs</h3>
      <Breadcrumb>
        <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/admin-dashboard">
          Admin Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>View Job Applications</Breadcrumb.Item>
      </Breadcrumb>
      <div className="table-responsive">
      <Table striped bordered hover className="shadow">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Applicant Name</th>
            <th>Resume</th>
            <th>Status</th>
            <th>Reason</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {applications
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((app, index) => (
              <tr key={app.id}>
                <td>{index + 1}</td>
                <td>{app.name}</td>
                <td>
                  <a
                    href={`${apiUrl}/resume/download/${app.resume}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Resume
                  </a>
                </td>
                <td>
                  {currentStatus[app.id]?.status === "Rejected" ? (
                    <Button variant="danger" disabled>
                      Rejected
                    </Button>
                  ) : (
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {currentStatus[app.id]?.status || "Pending"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {statusOptions.map((status) => (
                          <Dropdown.Item
                            key={status}
                            onClick={() => handleStatusChange(app, status)}
                          >
                            {status}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </td>
                <td>
                  {currentStatus[app.id]?.reasons?.length
                    ? currentStatus[app.id].reasons.join(", ")
                    : "No reason provided"}
                </td>
                <td>
                  {currentStatus[app.id]?.status === "Selected" && (
                    <Button
                      variant="success"
                      onClick={() => generateOfferLetter(app)}
                    >
                      Generate Offer Letter
                    </Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      </div>

      <Pagination className="d-flex justify-content-end">
        <Pagination.Prev
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        />
        {[...Array(totalPages).keys()].map((number) => (
          <Pagination.Item
            key={number}
            active={number + 1 === currentPage}
            onClick={() => setCurrentPage(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        />
      </Pagination>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Select Reason for {currentStatus[selectedCandidate?.id]?.status}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {reasons.map((reason) => (
              <Form.Check
                key={reason}
                type="checkbox"
                label={reason}
                value={reason}
                checked={selectedReasons.includes(reason)}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedReasons((prev) =>
                    e.target.checked
                      ? [...prev, value]
                      : prev.filter((r) => r !== value)
                  );
                }}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ApplicationsPage;
