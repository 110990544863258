import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Accordion, Button, Container, Breadcrumb } from "react-bootstrap";

const DocumentUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  // Handle file selection
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!selectedFile) {
      Swal.fire("Error", "Please select a file to upload.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("document", selectedFile);

    try {
      // Ensure the URL matches your backend route
      const response = await axios.post(`${apiUrl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Show success message using SweetAlert
      Swal.fire("Success", response.data.message, "success");

      // Update uploaded files list
      setUploadedFiles([...uploadedFiles, response.data.file.filename]);
    } catch (error) {
      // Show error message using SweetAlert
      Swal.fire("Error", "Failed to upload file.", "error");
    }
  };

  // Handle file deletion
  const handleDelete = async (filename) => {
    try {
      await axios.delete(`${apiUrl}/documents/${filename}`);

      // Show confirmation dialog
      Swal.fire({
        title: "Deleted!",
        text: "File deleted successfully.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        // Refresh page after user confirms
        window.location.reload();
      });
    } catch (error) {
      console.error("Error deleting file:", error);

      // Show error message using SweetAlert
      Swal.fire("Error", "Failed to delete file.", "error");
    }
  };

  // Handle file view
  const handleView = (filename) => {
    if (filename) {
      window.open(`${apiUrl}/documents/${filename}`, "_blank");
    } else {
      console.error("Filename is undefined");
    }
  };

  return (
    <Container>
      <h2 className="text-primary my-4">Legal Employee Document </h2>
      <Breadcrumb>
        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Employee Profile</Breadcrumb.Item>
      </Breadcrumb>

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="text-primary fw-2">
            Upload Documents
          </Accordion.Header>
          <Accordion.Body>
            <input type="file" onChange={handleFileChange} />
            <Button variant="primary" onClick={handleUpload}>
              Upload Document
            </Button>

            <ul className="list-group mt-4">
              {uploadedFiles.map((file, index) => (
                <li key={file.id} className="list-group-item fs-4 fw-2">
                  {file.file_name}
                  <div className="float-right mt-3">
                    <Button
                      variant="link"
                      className="mr-2 fs-5 fw-3"
                      onClick={() => handleView(file.file_name)}
                    >
                      View
                    </Button>
                    {/* <Button variant="link" className="mr-2 fs-5 fw-3">
                Edit
              </Button> */}
                    <Button
                      variant="link"
                      className="mr-2 fs-5 fw-3"
                      onClick={() => handleDelete(file)}
                    >
                      Delete
                    </Button>
                  </div>
                </li>
              ))}
            </ul>

            {/* <Accordion.Header className='text-primary fw-2'>Personal Documents</Accordion.Header>
          <Accordion.Body>
            <ul className="list-group">
              <li className="list-group-item fs-4 fw-2 ">
                Aadhar Card
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className='mr-2 fs-5 fw-3'>Delete</Button>
                  <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
                </div>
               
              </li>
              <li className="list-group-item fs-4 fw-2">
                PAN Card
                <div className="float-right mt-3">
                  <Button variant="link"className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
              <li className="list-group-item fs-4 fw-2">
                Photo
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
            </ul>
          // </Accordion.Body> */}
          </Accordion.Body>
        </Accordion.Item>

        {/* <Accordion.Item eventKey="1" className='mt-3'>
          <Accordion.Header>Educational Documents</Accordion.Header>
          <Accordion.Body>
            <ul className="list-group">
              <li className="list-group-item fs-4 fw-2">
                SSC Certificate
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
              <li className="list-group-item fs-4 fw-2">
                HSC Certificate
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
              <li className="list-group-item fs-4 fw-2">
                Graduation Certificate
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
              <li className="list-group-item fs-4 fw-2">
                Post Graduation Certificate
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link"className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item> */}

        {/* <Accordion.Item eventKey="2"className='mt-3'>
          <Accordion.Header>Other Documents</Accordion.Header>
          <Accordion.Body>
            <ul className="list-group">
              <li className="list-group-item fs-4 fw-2">
                Experience Certificate
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
              <li className="list-group-item fs-4 fw-2">
                Relieving Letter
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
              <li className="list-group-item fs-4 fw-2">
                Salary Slip 1
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
              <li className="list-group-item fs-4 fw-2">
                Salary Slip 2
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
              <li className="list-group-item fs-4 fw-2">
                Salary Slip 3
                <div className="float-right mt-3">
                  <Button variant="link" className="mr-2 fs-5 fw-3">View</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Edit</Button>
                  <Button variant="link" className="mr-2 fs-5 fw-3">Delete</Button>
                </div>
                <div className="mb-4 d-flex justify-content-end">
        <Button variant="primary">Upload Document</Button>
      </div>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item> */}
      </Accordion>
    </Container>
  );
};

export default DocumentUpload;
