import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FaBell, FaEnvelope, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const CustomNavbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  const logoutThreshold = 24 * 60 * 60 * 1000; 
  // const logoutThreshold = 10 * 60 * 1000; 

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";


  useEffect(() => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("userEmail");
    const loginTimeFirst = localStorage.getItem("loginTimeFirst");

    if (token) {
      setIsLoggedIn(true);

      const fetchUserRole = async () => {
        try {
          const response = await axios.get(`${apiUrl}/auth/users`);
          const user = response.data.find((user) => user.email === email);
          if (user) {
            setUserRole(user.role);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserRole();

      // Check if the user session has expired
      if (loginTimeFirst) {
        const timeSinceLogin = Date.now() - parseInt(loginTimeFirst, 10);
        if (timeSinceLogin >= logoutThreshold) {
          handleAutoLogout();
        }
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("empId");
    localStorage.removeItem("loginTime");
    localStorage.removeItem("logoutTime");
    localStorage.removeItem("loginTimeFirst");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    setUserRole(null);
    setIsLoggedIn(false);

    Swal.fire("Logged Out", "You have been logged out successfully.", "success");
    navigate("/");
    window.location.reload();
  };

  const handleAutoLogout = () => {
    Swal.fire(
      "Session Expired",
      "Your session has expired due to inactivity. Please log in again.",
      "warning"
    ).then(() => {
      handleLogout();
    });
  };

  const handleLogin = (role) => {
    const loginTimeFirst = Date.now();
    localStorage.setItem("loginTimeFirst", loginTimeFirst); // Store login time in local storage

    switch (role) {
      case "HR":
        navigate("/hr-login");
        break;
      case "Candidate":
        navigate("/login");
        break;
      case "Employee":
        navigate("/employee-login");
        break;
      default:
        navigate("/login");
    }
  };
 
  const handleDashboard = () => {
    const role = localStorage.getItem("role");
    const empId = localStorage.getItem("empId");
    if (role) {
      if (role === "HR") {
        window.location.href = "/hr-dashboard";
      } else if (role === "Candidate") {
        window.location.href = "/candidate-dashboard";
      } else if (role === "Employee") {
        window.location.href = `/employee-dashboard/${empId}`;
      }
    } else {
      console.log("No role defined; check userRole value:", userRole);
    }
  };

  return (
    <Navbar expand="lg" style={{ backgroundColor: "#fff" }}>
      <Navbar.Brand href="/" className="ms-3 d-flex align-items-center">
        <img
          src="images\logohr.jpg"
          alt="Logo"
          style={{ width: "200px", height: "80px" }}
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav" className="justify-content-end">
        <Nav className="me-3 d-flex align-items-center">
          {isLoggedIn ? (
            <>
              <Nav.Link href="#notifications">
                <FaBell size={30} style={{ color: "#f8701b" }} />
              </Nav.Link>
              <Nav.Link href="#messages">
                <FaEnvelope size={30} style={{ color: "#f8701b" }} />
              </Nav.Link>
              <NavDropdown
                title={<FaUser size={30} style={{ color: "#f8701b" }} />}
                id="user-dropdown"
                align="end"
              >
                <NavDropdown.Item onClick={handleDashboard}>
                  Dashboard
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </>
          ) : (
            <NavDropdown
              title={<FaUser size={30} style={{ color: "#f8701b" }} />}
              id="user-dropdown"
              align="end"
            >
              <NavDropdown.Item
                style={{ backgroundColor: "#f8701b", color: "#fff" }}
                onClick={() => handleLogin("HR")}
              >
                HR/Admin Login
              </NavDropdown.Item>

              <NavDropdown.Item
                style={{ backgroundColor: "#f8701b", color: "#fff" }}
                onClick={() => handleLogin("Employee")}
              >
                Employee Login
              </NavDropdown.Item>
              {/* <NavDropdown.Item
                style={{ backgroundColor: "#f8701b", color: "#fff" }}
                onClick={() => handleLogin("Candidate")}
              >
                Candidate Login
              </NavDropdown.Item> */}
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
