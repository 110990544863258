import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container, Button, Spinner } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const EmployeeDetailsPage = () => {
  const { id } = useParams(); 
  console.log("empId",id);
  const [employee, setEmployee] = useState(null);
  console.log("employeee", employee);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost
    ? "http://localhost:5000/api"
    : "https://www.mayuhrsolutions.com/api";

    const empId = localStorage.getItem("empId"); 
  // Fetch employee details on page load
  const fetchEmployeeDetails = async () => {
    try {
      const response = await axios.get(`${apiUrl}/employees?empId=${id}`);
      console.log("===", response);
      setEmployee(response?.data); // Store employee details
      console.log("response", response?.data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
      Swal.fire("Error", "Failed to fetch employee details", "error");
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchEmployeeDetails(); 
  }, [id]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!employee) {
    return (
      <div className="text-center mt-5">
        <h3>No employee found</h3>
        <Button onClick={() => navigate(`/employee-dashboard/${id}`)}>Back to List</Button>
      </div>
    );
  }

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4 text-primary">Employee Details</h2>
      <Card className="p-4 shadow-lg mb-4">
        <h4 className="text-secondary">Personal Information</h4>
        <Row className="mt-3">
          <Col md={4}><strong>Name:</strong> {employee.employeeName}</Col>
          <Col md={4}><strong>Email:</strong> {employee.email}</Col>
          <Col md={4}><strong>Contact:</strong> {employee.contactNumber}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}><strong>Gender:</strong> {employee.gender}</Col>
          <Col md={4}><strong>Date of Birth:</strong> {employee.dob}</Col>
          <Col md={4}><strong>Blood Group:</strong> {employee.bloodGroup}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}><strong>Permanent Address:</strong> {employee.permanentAddress}</Col>
          <Col md={4}><strong>Current Address:</strong> {employee.currentAddress}</Col>
        </Row>
      </Card>

      <Card className="p-4 shadow-lg mb-4">
        <h4 className="text-secondary">Professional Information</h4>
        <Row className="mt-3">
          <Col md={4}><strong>Job Title:</strong> {employee.jobTitle}</Col>
          <Col md={4}><strong>Employee ID:</strong> {employee.empId}</Col>
          <Col md={4}><strong>Department:</strong> {employee.department}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}><strong>Team:</strong> {employee.team}</Col>
          <Col md={4}><strong>Manager:</strong> {employee.manager}</Col>
          <Col md={4}><strong>Joining Date:</strong> {employee.joiningDate}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}><strong>Education:</strong> {employee.education}</Col>
          <Col md={4}><strong>Annual CTC:</strong> ₹{employee.annualCTC}</Col>
          <Col md={4}><strong>Official Email:</strong> {employee.officialEmail}</Col>
        </Row>
      </Card>

      <Card className="p-4 shadow-lg mb-4">
        <h4 className="text-secondary">Bank Account Details</h4>
        <Row className="mt-3">
          <Col md={4}><strong>Account Number:</strong> {employee.accountNumber}</Col>
          <Col md={4}><strong>IFSC Code:</strong> {employee.ifscCode}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}><strong>Branch Name:</strong> {employee.branchName}</Col>
          <Col md={8}><strong>Branch Address:</strong> {employee.branchAddress}</Col>
        </Row>
      </Card>

      <div className="text-center">
        <Button variant="secondary" onClick={() => navigate(`/employee-dashboard/${id}`)}>
          Back to Employee List
        </Button>
      </div>
    </Container>
  );
};

export default EmployeeDetailsPage;
