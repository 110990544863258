import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import {
  FaUserTie,
 
  FaUser,
  
} from "react-icons/fa";

function HomePage() {
  const [userType, setUserType] = useState(null);
  const [empId, setEmpId] = useState(null);

  // Check if a user is logged in when the page loads
  useEffect(() => {
    const loggedInUserType = localStorage.getItem("role");
    const storedEmpId = localStorage.getItem("empId");
    if (loggedInUserType) {
      setUserType(loggedInUserType);
    }
    if (storedEmpId) {
      setEmpId(storedEmpId);
    }
  }, []);

  // const handleLogout = () => {
    
  //   localStorage.removeItem("role");
  //   setUserType(null);
  // };

  const getDashboardUrl = () => {
    if (userType === "HR") {
      return "/hr-dashboard";
    } else if (userType === "Employee") {
      return `/employee-dashboard/${empId}`;
    }
    return "/";
  };

  return (
    <div className="home-page">
      <section className="section section-1">
        <Container className="hero-section text-center">
          <h1 className="mt-5 hero-title" style={{ color: "#f8701b" }}>
            Welcome to Our HR Portal
          </h1>
          <p className="hero-subtitle">
            {userType ? `${userType} Dashboard` : "Log in as HR, or Employee"}
          </p>
          <Row className="mt-2">
            <Col md={12} className="mt-3">
              <h5>About Us</h5>
              <p className="hero-subtitle">
                We specialize in a range of digital services including IT
                Students Internship Programs, Web Development, Android App
                Development, CRM Solutions, OTT Platforms, and HR Services.
              </p>
              {/* {
              userType === "Candidate" && (
                <p className="text-center">
                  <h4>Let's Begin Your Career, With Us</h4>
                  <Button href="/career" className="apply-button" style={{ backgroundColor: "#f8701b", color: "#fff" }}>
                    Apply Now <FaArrowRight className="ms-2" />
                  </Button>
                </p>
              )
            } */}
            </Col>
          </Row>

          {/* Render different content based on whether user is logged in */}
          {userType ? (
           <Row className="justify-content-center mt-5 p-0">
           <Col md={4} lg={2} xs={12} className="mb-3">
             <Card className="login-card shadow">
               <Card.Body>
                 <FaUserTie
                   className="me-2 mt-4"
                   size={30}
                   style={{ color: "#f8701b" }}
                 />
                 <Card.Title className="mt-4 text-primary">
                   {userType} Dashboard
                 </Card.Title>
                 <Button
                   style={{ backgroundColor: "#f8701b" }}
                   href={getDashboardUrl()}
                   className="w-100 mt-3"
                 >
                   Dashboard
                 </Button>
               </Card.Body>
             </Card>
           </Col>
         </Row>
          ) : (
            // Show login options if no user is logged in
            <Row className="justify-content-center mt-5 p-0">
              <Col md={4} lg={2} xs={12} className="mb-3 ">
                <Card className="login-card shadow">
                  <Card.Body>
                    <FaUserTie
                      className="me-2 mt-4"
                      size={30}
                      style={{ color: "#f8701b" }}
                    />
                    <Card.Title className="mt-4 text-primary">
                      HR Login
                    </Card.Title>
                    <Button
                      style={{ backgroundColor: "#f8701b" }}
                      href="/hr-login"
                      className="w-100 mt-3"
                    >
                      Login
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col md={4} lg={2} xs={12} className="mb-3">
                <Card className="login-card shadow">
                  <Card.Body>
                    <FaUserGraduate className="me-2 mt-4" size={30} style={{ color: "#f8701b" }} />
                    <Card.Title className="mt-4 text-primary">Candidate Login</Card.Title>
                    <Button
                      style={{ backgroundColor: "#f8701b" }}
                      href="/login"
                      className="w-100 mt-3"
                    >
                      Login
                    </Button>
                  </Card.Body>
                </Card>
              </Col> */}
              <Col md={3} lg={2} xs={12} className="mb-3">
                <Card className="login-card shadow">
                  <Card.Body>
                    <FaUser
                      className="me-2 mt-4"
                      size={30}
                      style={{ color: "#f8701b" }}
                    />
                    <Card.Title className="mt-4 text-primary">
                      Employee Login
                    </Card.Title>
                    <Button
                      style={{ backgroundColor: "#f8701b" }}
                      href="/employee-login"
                      className="w-100 mt-3"
                    >
                      Login
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </div>
  );
}

export default HomePage;
