// components/Footer.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaEnvelope, FaPhone } from "react-icons/fa";

const Footer = () => {
  return (
    <footer
      className="text-white py-4 mt-auto"
      style={{ fontSize: "0.9rem", height: "25px" }}
    >
      <Container>
        <Row className="mb-4">
          <Col xs={12} sm={4} className="text-center mb-3 mb-sm-0">
            <p className="text-black fw-4">
              <b>Contact Us</b>
            </p>
          </Col>
          <Col xs={12} sm={4} className="text-center mb-3 mb-sm-0">
            <p>
              <FaEnvelope className="text-black me-2" />
              <a href="mailto:info@mbsgoi.com" className="text-black fw-4">
                <b>info@mbsgoi.com</b>
              </a>
            </p>
          </Col>
          <Col xs={12} sm={4} className="text-center">
            <p>
              <FaPhone className="me-2 text-black" />
              <a href="tel:+9405561601" className="text-black">
                <b>+91-9405561601</b>
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
