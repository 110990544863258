import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  Container,
  Breadcrumb,
  Card,
  Row,
  Col,
  Button,
  Form,
  Pagination,
  Alert,
} from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const EmployeeLogTable = ({ logs = [] }) => {
  const [employeeLogs, setEmployeeLogs] = useState([]);
  const navigate = useNavigate();
  const perPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  // const [filters, setFilters] = useState({
  //   empId: "",
  //   employeeName: "",
  //   department: "",
  //   date: null,
  // });
  const [date, setDate] = useState('');
  const [empId, setEmpId] = useState('');
  const [department, setDepartment] = useState('');
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  // Calculate the start and end index for slicing employees data based on currentPage
  const indexOfLastEmployee = currentPage * perPage;
  const indexOfFirstEmployee = indexOfLastEmployee - perPage;
  const currentEmployees = employeeLogs.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(employeeLogs.length / perPage);

  const departmentOptions = [
    "Mayu Sport Academy",
    "Mayu IT Planet",
    "Mayu E Learning",
    "Mayu TV",
    "Mayu E-Governance",
    "Mayu Business Service Group Of India",
  ];

  const [searchDate, setSearchDate] = useState("");

  // const filteredLogs = logs.filter((log) => {
  //   if (!searchDate) return true;

  //   const logDate = log.loginTime
  //     ? moment(log.loginTime).format("YYYY-MM-DD")
  //     : null;

  //   return logDate === searchDate;
  // });

 
  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  const fetchEmployeeLogs = async () => {
    setLoading(true);
    setApiError("");

    try {
      // Make the API request with filters and formatted date
      const response = await axios.get(`${apiUrl}/employee-logins`, {
        params: { date, department, empId },
      });

      // Check if the response is an array and set employee logs
      if (Array.isArray(response.data)) {
        setEmployeeLogs(response.data);
      } else {
        setApiError("Failed to load employee logs.");
      }
    } catch (error) {
      setApiError("Error fetching employee logs.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployeeLogs();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    fetchEmployeeLogs();
  };

  const handleCancel = () => {
   
    // fetchEmployeeLogs({});
   window.location.reload();
  };

  const calculateWorkProgress = (loginTime, logoutTime, workProgress) => {
    if (workProgress !== undefined) return workProgress.toFixed(2);

    const workingHours = 8;
    const loginMoment = moment(loginTime);
    const duration = logoutTime
      ? moment(logoutTime).diff(loginMoment, "hours", true)
      : moment().diff(loginMoment, "hours", true);

    return Math.min((duration / workingHours) * 100, 100).toFixed(2);
  };

  return (
    <Container
      className="border shadow p-4 mt-3"
      style={{ backgroundColor: "aliceblue" }}
    >
      <h2 className="mt-3" style={{ color: "#f8701b" }}>
        Daily Attendance
      </h2>
      <Breadcrumb>
        <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Daily Attendance</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-end ">
        <Button
          onClick={() => navigate("/hr-dashboard")}
          style={{ backgroundColor: "#f8701b" }}
        >
          Back to HR Dashboard
        </Button>
      </div>

      <div>
        <Card className="mt-4 p-4">
          <h4 style={{ color: "#f8701b" }}>Filter</h4>
          <Form className="mb-4">
            {error && <Alert variant="danger">{error}</Alert>}
            <Row className="align-items-center">
              <Col xs={12} md={4} className="mb-2">
                <Form.Group controlId="filterEmpId">
                  <Form.Label>Employee ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={empId}
                    onChange={(e) =>
                      setEmpId(e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4} className="mb-2">
                <Form.Group controlId="filterDepartment">
                  <Form.Label>Department</Form.Label>
                  <Form.Control
                    as="select"
                    id="department"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}

                  >
                    <option value="">Select Department</option>
                    {departmentOptions.map((dept, idx) => (
                      <option key={idx} value={dept}>
                        {dept}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} className="mb-2">
                <Form.Group controlId="filterDate">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="d-flex justify-content-start mt-3">
                <Button
                  className="me-2"
                  onClick={handleSearch}
                  style={{ backgroundColor: "#f8701b" }}
                >
                  Search
                </Button>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>

        <Card className="mt-5">
          <h4 className="mt-3 p-3" style={{ color: "#f8701b" }}>
            Employee Attendance
          </h4>
          <div className="table-responsive">
            <Table striped bordered hover responsive>
              <thead style={{ color: "#f8701b" }}>
                <tr>
                  <th>Sr. No.</th>
                  <th>Name</th>
                  <th>Department</th>
                  <th>EmpId</th>
                  <th>Login Time</th>
                  <th>Logout Time</th>
                  <th>Date</th>
                  <th>Work Progress (%)</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : apiError ? (
                  <tr>
                    <td colSpan="7" className="text-center text-danger">
                      {apiError}
                    </td>
                  </tr>
                ) : currentEmployees.length > 0 ? (
                  currentEmployees.map((log, index) => (
                    <tr key={log.srNo || index}>
                      <td>{log.srNo || index + 1 + indexOfFirstEmployee}</td>
                      <td>{log.employeeName}</td>
                      <td>{log.department}</td>
                      <td>{log.empId}</td>
                      <td>
                        {log.loginTime
                          ? moment(log.loginTime).format("hh:mm A")
                          : "N/A"}
                      </td>
                      <td>
                        {log.logoutTime
                          ? moment(log.logoutTime).format("hh:mm A")
                          : "-"}
                      </td>
                      <td>
                        {log.loginTime
                          ? moment(log.loginTime).format("YYYY-MM-DD")
                          : "-"}
                      </td>
                      <td>{log.workProgress.toFixed(2)}</td>
                      {/* <td>{calculateWorkProgress(log.workProgress)}%</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <div className="d-flex justify-content-end">
            <Pagination>
  {/* Previous Button */}
  <Pagination.Prev
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  />

  {/* First Page */}
  {currentPage > 2 && (
    <>
      <Pagination.Item onClick={() => handlePageChange(1)}>
        1
      </Pagination.Item>
      {currentPage > 3 && <Pagination.Ellipsis />}
    </>
  )}

  {/* Current and Surrounding Pages */}
  {[...Array(totalPages)]
    .map((_, index) => index + 1)
    .filter(
      (page) =>
        page === currentPage || // Always show the current page
        page === currentPage - 1 || // One page before
        page === currentPage + 1 // One page after
    )
    .map((page) => (
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    ))}

  {/* Last Page */}
  {currentPage < totalPages - 1 && (
    <>
      {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
      <Pagination.Item onClick={() => handlePageChange(totalPages)}>
        {totalPages}
      </Pagination.Item>
    </>
  )}

  {/* Next Button */}
  <Pagination.Next
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  />
</Pagination>
            </div>
          </div>
        </Card>
      </div>
    </Container>
  );
};

export default EmployeeLogTable;
