import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  // Breadcrumb,
  Pagination,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BsGeoAltFill } from "react-icons/bs";
// import Swal from "sweetalert2";

const JobListingPage = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filters, setFilters] = useState({
    jobType: [],
    jobCategory: [],
    location: "",
    experienceLevel: "",
    salaryRange: "",
    companyName: "",
  });

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";
  
  const navigate = useNavigate();

  // Fetch job listings and dynamic filters from API
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/jobs`);
        setJobs(response.data);
        setFilteredJobs(response.data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };
    fetchJobs();
  }, [apiUrl]);

  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  // Handle form field change
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    const updatedArray = checked
      ? [...filters[name], value]
      : filters[name].filter((v) => v !== value);
    setFilters({ ...filters, [name]: updatedArray });
  };

  // Filter jobs based on the form values
  const handleSearch = () => {
    const filtered = jobs.filter((job) => {
      return (
        (filters.jobType.length === 0 ||
          filters.jobType.includes(job.jobType)) &&
        (filters.jobCategory.length === 0 ||
          filters.jobCategory.includes(job.category)) &&
        (filters.location === "" ||
          job.location
            .toLowerCase()
            .includes(filters.location.toLowerCase())) &&
        (filters.experienceLevel === "" ||
          job.experience === filters.experienceLevel) &&
        (filters.salaryRange === "" || job.salary === filters.salaryRange) &&
        (filters.companyName === "" ||
          job.companyName
            .toLowerCase()
            .includes(filters.companyName.toLowerCase()))
      );
    });
    setFilteredJobs(filtered);
    setCurrentPage(1);
  };

  // Reset filters and show all jobs
  const handleCancel = () => {
    setFilters({
      jobType: [],
      jobCategory: [],
      location: "",
      experienceLevel: "",
      salaryRange: "",
      companyName: "",
    });
    setFilteredJobs(jobs);
  };

  // Pagination logic
  const totalPages = Math.ceil(filteredJobs.length / itemsPerPage);
  const paginatedJobs = filteredJobs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleApply = (jobId) => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login", { state: { jobId } });
    } else {
      navigate(`/apply/${jobId}`);
    }
  };

  return (
    <Container>
      <h2 className="mt-3 registration-heading">Current Job Opening</h2>
      <Card>
        <Row className="ms-3">
          <Col md={4}>
            <Form>
              <Form.Group controlId="jobType">
                <Form.Label className="mt-2 fs-4">Job Type</Form.Label>
                <div>
                  <Form.Check
                    type="checkbox"
                    label="Full-time"
                    name="jobType"
                    value="Full-time"
                    className="ms-4"
                    checked={filters.jobType.includes("Full-time")}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Part-time"
                    name="jobType"
                    value="Part-time"
                    className="mt-3 ms-4"
                    checked={filters.jobType.includes("Part-time")}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Freelance"
                    name="jobType"
                    value="Freelance"
                    className="mt-3 ms-4"
                    checked={filters.jobType.includes("Freelance")}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Contract"
                    name="jobType"
                    value="Contract"
                    className="mt-3 ms-4"
                    checked={filters.jobType.includes("Contract")}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Internship"
                    name="jobType"
                    value="Internship"
                    className="mt-3 ms-4"
                    checked={filters.jobType.includes("Internship")}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </Form.Group>

              <Form.Group controlId="jobCategory">
                <Form.Label className="mt-2 fs-4">Job Category</Form.Label>
                <div>
                  <Form.Check
                    type="checkbox"
                    label="IT"
                    name="jobCategory"
                    value="IT"
                    className="mt-3 ms-4"
                    checked={filters.jobCategory.includes("IT")}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Construction"
                    name="jobCategory"
                    value="Construction"
                    className="mt-3 ms-4"
                    checked={filters.jobCategory.includes("Construction")}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Management"
                    name="jobCategory"
                    value="Management"
                    className="mt-3 ms-4"
                    checked={filters.jobCategory.includes("Management")}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Healthcare"
                    name="jobCategory"
                    value="Healthcare"
                    className="mt-3 ms-4"
                    checked={filters.jobCategory.includes("Healthcare")}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </Form.Group>

              <Form.Group controlId="experienceLevel">
                <Form.Label className="mt-2 fs-4">Experience Level</Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="Entry-level"
                    name="experienceLevel"
                    value="Entry-level"
                    className="ms-4 mt-3"
                    checked={filters.experienceLevel === "Entry-level"}
                    onChange={handleFilterChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Mid-level"
                    name="experienceLevel"
                    value="Mid-level"
                    className="ms-4 mt-3"
                    checked={filters.experienceLevel === "Mid-level"}
                    onChange={handleFilterChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Senior-level"
                    name="experienceLevel"
                    value="Senior-level"
                    className="ms-4 mt-3"
                    checked={filters.experienceLevel === "Senior-level"}
                    onChange={handleFilterChange}
                  />
                </div>
              </Form.Group>

              <Form.Group controlId="location">
                <Form.Label className="mt-2 fs-4">Location</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter location"
                  name="location"
                  value={filters.location}
                  onChange={handleFilterChange}
                />
              </Form.Group>

              {/* <Form.Group controlId="companyName">
                <Form.Label className="mt-2 fs-4">Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  name="companyName"
                  value={filters.companyName}
                  onChange={handleFilterChange}
                />
              </Form.Group> */}

              <Button
                variant="primary"
                className="mt-4 me-3"
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                variant="secondary"
                className="mt-4"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form>
          </Col>

          <Col md={8}>
            {paginatedJobs.length === 0 ? (
              <h1 className="d-flex justify-content-center text-primary">
                No jobs found
              </h1>
            ) : (
              paginatedJobs.map((job) => (
                <Card key={job.id} className="m-3">
                  <Card.Body>
                    <Card.Title>{job.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {job.companyName} <BsGeoAltFill className="ms-4" />{" "}
                      {job.location}
                    </Card.Subtitle>
                    <Card.Text>
                      <strong>Skills:</strong> {job.skills}
                    </Card.Text>
                    <Card.Text>
                      <strong>Experience:</strong> {job.experience}
                    </Card.Text>
                    <Card.Text>
                      <strong>Description:</strong> {job.description}
                    </Card.Text>
                    <Card.Text>
                      <strong>Salary:</strong> {job.salary}
                    </Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => handleApply(job.id)}
                    >
                      Apply Now
                    </Button>
                  </Card.Body>
                </Card>
              ))
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
        <Pagination>
  {/* Previous Button */}
  <Pagination.Prev
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  />

  {/* First Page */}
  {currentPage > 2 && (
    <>
      <Pagination.Item onClick={() => handlePageChange(1)}>
        1
      </Pagination.Item>
      {currentPage > 3 && <Pagination.Ellipsis />}
    </>
  )}

  {/* Current and Surrounding Pages */}
  {[...Array(totalPages)]
    .map((_, index) => index + 1)
    .filter(
      (page) =>
        page === currentPage || // Always show the current page
        page === currentPage - 1 || // One page before
        page === currentPage + 1 // One page after
    )
    .map((page) => (
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    ))}

  {/* Last Page */}
  {currentPage < totalPages - 1 && (
    <>
      {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
      <Pagination.Item onClick={() => handlePageChange(totalPages)}>
        {totalPages}
      </Pagination.Item>
    </>
  )}

  {/* Next Button */}
  <Pagination.Next
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  />
</Pagination>
</div>
      </Card>
    </Container>
  );
};

export default JobListingPage;
