import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Breadcrumb,
  Modal,
  InputGroup,
} from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

function AddJobPage({ jobs = [], setJobs }) {
  const [jobData, setJobData] = useState({
    category: "",
    type: "",
    experience: "",
    location: "",
    title: "",
    companyName: "",
    salary: "",
    skills: "",
    description: "",
  });

  const ctcOptions = ["1.80 LPA", "1.50 LPA", "2.40 LPA", "3.00LPA", "4.20 LPA", "6.00 LPA"];

  const designationOptions = ["Business Development Executive", "Business Development Manager", "Relational Manager",
    "Operational Manager", "Business Head", "Project Manager", "Assistant Manager", "HR Executive", "Trainer", "Accountant",
    "Web developer", "Android Developer", "AWS Engineer","IT- Trainer", "UI/UX Designer", "Marketing Manager",
    "Chief Executive officer","Managing Director"
  ];

  const [validator] = useState(
    new SimpleReactValidator({
      element: (message) => <span style={{ color: "red" }}>{message}</span>,
    })
  );

  const navigate = useNavigate();
  const { id } = useParams();

  // Manage dropdown options
  const [categories, setCategories] = useState([
    "Engineering",
    "IT",
    "Management",
    "Healthcare",
    "Construction",
  ]);
  const [types, setTypes] = useState([
    "Full-time",
    "Part-time",
    "Freelance",
    "Contract",
    "Internship",
  ]);
  const [experienceLevels, setExperienceLevels] = useState([
    "Entry-level",
    "Mid-level",
    "Senior-level",
  ]);

  // Modal control
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(""); 
  const [newOption, setNewOption] = useState("");

  // Check if the app is running on localhost (development)
  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000/api" // Local development
    : "https://www.mayuhrsolutions.com/";

  useEffect(() => {
    if (id) {
      // If editing an existing job
      console.log("iddd", id);
      axios
        .get(`${apiUrl}/jobs/${id}`) // Fetch job by ID
        .then((response) => {
          console.log("response", response);
          setJobData(response.data); // Set job data in the form
        })
        .catch((error) => {
          console.error("Error fetching job:", error);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    setJobData({ ...jobData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // if (validator.allValid()) {
      if (id !== undefined) {
        axios
          .put(`${apiUrl}/jobs/${id}`, jobData)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              const updatedJobs = jobs.map((job) =>
                job.id === parseInt(id) ? { ...job, ...jobData } : job
              );
              setJobData(updatedJobs);
  
              Swal.fire({
                title: "Success!",
                text: "Job updated successfully.",
                icon: "success",
                confirmButtonText: "OK",
              });
  
              navigate("/admin-dashboard");
            }
          })
          .catch((error) => {
            console.error("Error during update:", error.response || error.message);
  
            Swal.fire({
              title: "Error!",
              text: error.response ? error.response.data.message : "An error occurred.",
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      } else {
        axios
          .post(`${apiUrl}/jobs`, jobData)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              setJobData([...jobs, response.data]);
  
              Swal.fire({
                title: "Success!",
                text: "Job added successfully.",
                icon: "success",
                confirmButtonText: "OK",
              });
  
              navigate("/admin-dashboard");
            }
          })
          .catch((error) => {
            console.error("Error during addition:", error.response || error.message);
  
            Swal.fire({
              title: "Error!",
              text: error.response ? error.response.data.message : "An error occurred.",
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }
    // } else {
      validator.showMessages();
      setJobData({ ...jobData }); // Triggers re-render to show validation messages
    // }
  };
  

  const handleCancel = () => {
    setJobData({
      category: "",
      type: "",
      experience: "",
      location: "",
      salary: "",
      skills: "",
      description: "",
      
    });
    validator.hideMessages();
  };

  return (
    <div className="container">
      <h1 className="registration-heading mt-3">
        {id !== undefined ? "Edit Job" : "Add Job"}
      </h1>
      <Breadcrumb>
        <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/admin-dashboard">
          Admin Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Add Job</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        onSubmit={handleSubmit}
        className="bg-white mb-3 p-4 border border-secondary rounded"
      >
        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="category">
              <Form.Label>
                Job Category<span className="text-danger">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  as="select"
                  name="category"
                  value={jobData.category}
                  onChange={handleChange}
                >
                  <option value="">Select a category</option>
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
              {validator.message("category", jobData.category, "required")}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="type">
              <Form.Label>
                Job Type<span className="text-danger">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  as="select"
                  name="type"
                  value={jobData.type}
                  onChange={handleChange}
                >
                  <option value="">Select a job type</option>
                  {types.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
              {validator.message("type", jobData.type, "required")}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="experience">
              <Form.Label>
                Experience Level<span className="text-danger">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  as="select"
                  name="experience"
                  value={jobData.experience}
                  onChange={handleChange}
                >
                  <option value="">Select experience level</option>
                  {experienceLevels.map((level, index) => (
                    <option key={index} value={level}>
                      {level}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
              {validator.message("experience", jobData.experience, "required")}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="location">
              <Form.Label>
                Location<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={jobData.location}
                onChange={handleChange}
              />
              {validator.message("location", jobData.location, "required")}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="title">
              <Form.Label>
                Designation <span className="text-danger">*</span>
              </Form.Label>
             <Form.Select
                      name="title"
                      value={jobData.title}
                      onChange={handleChange}
                      // isInvalid={!!errors.jobTitle}
                    >
                      <option value="">Select Designation</option>
                      {designationOptions.map((designation, index) => (
                        <option key={index} value={designation}>
                          {designation}
                        </option>
                      ))}
                    </Form.Select>
              {validator.message("title", jobData.title, "required")}
            </Form.Group>
          </Col>

          {/* Company Name Field */}
          <Col md={6}>
            <Form.Group controlId="companyName">
              <Form.Label>
                Company Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                value={jobData.companyName}
                onChange={handleChange}
              />
              {validator.message(
                "companyName",
                jobData.companyName,
                "required"
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
           <Form.Group controlId="salary">
                    <Form.Label>
                      Annual CTC<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="salary"
                      value={jobData.salary}
                      onChange={handleChange}
                      // isInvalid={!!error.annualCTC}
                    >
                      <option value="">Select Annual CTC</option>
                      {ctcOptions.map((ctc, index) => (
                        <option key={index} value={ctc}>
                          {ctc}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                    {validator.message("salary", jobData.salary, "required")}

                    </Form.Control.Feedback>
                  </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="skills">
              <Form.Label>Skills</Form.Label>
              <Form.Control
                type="text"
                name="skills"
                value={jobData.skills}
                onChange={handleChange}
              />
              {validator.message("skills", jobData.skills, "required")}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={12}>
            <Form.Group controlId="description">
              <Form.Label>Job Description<span className="text-danger">*</span></Form.Label>
              <Form.Control
                as="textarea"
                rows={5}

                name="description"
                value={jobData.description}
                onChange={handleChange}
              />
              {validator.message(
                "description",
                jobData.description,
                "required"
              )}
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-4">
          <Button variant="primary" className="me-3" type="submit">
            {id !== undefined ? "Update Job" : "Add Job"}
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AddJobPage;
