// ExportButton.js
import React from "react";
import { Button, Container, Card, Col, Row, } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ExportButton = () => {
  const navigate = useNavigate();

  const apiUrl =
    window.location.hostname === "localhost"
      ? "http://localhost:5000/api"
      : window.location.hostname === "3.108.171.148"
      ? "http://3.108.171.148:5000/api"
      : "https://www.mayuhrsolutions.com/api";

  // const handleExport = async () => {
  //   try {
  //     // Send GET request to the backend to fetch the Excel file
  //     const response = await fetch(`${apiUrl}/export-data`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (response.ok) {
  //       // Create a Blob from the response data
  //       const blob = await response.blob();
  //       const link = document.createElement("a");
  //       const url = window.URL.createObjectURL(blob);
  //       link.href = url;
  //       link.setAttribute("download", "employees_data.xlsx");
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //     } else {
  //       console.error("Failed to fetch the file");
  //     }
  //   } catch (error) {
  //     console.error("Error exporting data:", error);
  //   }
  // };

  const downloadEmployeesLogin = () => {
    window.location.href = `${apiUrl}/export-employees-login`;
  };

  const downloadEmployees = () => {
    window.location.href = `${apiUrl}/export-employees`;
  };

  const downloadEmployeesLeaves = () => {
    window.location.href = `${apiUrl}/export-leaves`;
  };

  const downloadEmployeesEnquiry = () =>{
    window.location.href = `${apiUrl}/export-visitors`;
  };

  return (
    <Container className="mt-5">
      <div
        className="border shadow p-4"
        style={{ backgroundColor: "aliceblue" }}
      >
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => navigate("/hr-dashboard")}
            style={{ backgroundColor: "#f8701b" }}
          >
            Back to HR Dashboard
          </Button>
        </div>
        <Card className="border shadow p-4 mt-3">
          <h1 className="registration-heading mt-3">
            Export Data in the form of Excel-Sheet
          </h1>
          {/* <div className="mt-3 d-flex justify-content-center"> */}
          <Row>
          <Col md={4}>
            <h5 style={{ color: "#f8701b" }}>Export data of Employee Attendance</h5>
           
             
            <Button onClick={downloadEmployeesLogin}>
              Download Employees Attendance
            </Button>
           </Col>
           <Col md={4}>
            <h5 style={{ color: "#f8701b" }}>Export data of Employee Information</h5>
            <Button onClick={downloadEmployees}>Download Employees Information</Button>
            </Col>
            <Col md={4}>
            <h5 style={{ color: "#f8701b" }}>Export data of Employee Leaves</h5>
            <Button onClick={downloadEmployeesLeaves}>Download Employees Leaves</Button>
            </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
               <h5 style={{ color: "#f8701b" }}>Export data of Employee Enquiry Form</h5>
            <Button onClick={downloadEmployeesEnquiry}>Download Employees Enquiry Form</Button>
              </Col>
            </Row>
          {/* </div> */}
        </Card>
      </div>
    </Container>
  );
};

export default ExportButton;
