// ExportButton.js
import React from 'react';
import { Button, Container} from 'react-bootstrap';

const ExportButton = () => {

    const apiUrl =
    window.location.hostname === "localhost"
      ? "http://localhost:5000/api"
      : "https://www.mayuhrsolutions.com/api";

  const handleExport = async () => {
    try {
      // Send GET request to the backend to fetch the Excel file
      const response = await fetch(`${apiUrl}/export-data`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        // Create a Blob from the response data
        const blob = await response.blob();
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'employees_data.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error('Failed to fetch the file');
      }
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

// const handleExportEmployeesLogin = () => {
//     // Redirect to the backend endpoint to download the Excel file
//     window.location.href = 'http://localhost:5000/export-employees-login';
//   };

//   // Function to handle exporting employee data
//   const handleExportEmployee = () => {
//     // Redirect to the backend endpoint to download the Excel file
//     window.location.href = 'http://localhost:5000/export-employee';
//   };

  return (
   
    <Container className="mt-5">
         <Button onClick={handleExport}>
      Download Excel
    </Button>
         {/* <Button onClick={handleExportEmployeesLogin}>Export Employees Login Data</Button>
         <Button onClick={handleExportEmployee}>Export Employee Data</Button> */}
    </Container>
  );
};

export default ExportButton;
