import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import {
  Form,
  Row,
  Col,
  Button,
  Table,
  Card,
  Container,
  Pagination,
  Breadcrumb,
  Alert,
} from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import "chart.js/auto"; // Auto import chart.js for charts
import { useNavigate } from "react-router-dom";

const PerformanceReport = () => {
  const navigate = useNavigate();
  const [performanceData, setPerformanceData] = useState([]);
  const [filteredEmployee, setFilteredEmployee] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showDetailed, setShowDetailed] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [empId, setEmpId] = useState("");
  const [department, setDepartment] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [error, setError] = useState("");
  const [filters, setFilters] = useState({
    employeeName: "",
    month: "",
    year: "",
  });
  const departmentOptions = [
    "Mayu Sport Academy",
    "Mayu IT Planet",
    "Mayu E Learning",
    "Mayu TV",
    "Mayu E-Governance",
    "Mayu Business Service Group Of India",
  ];
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [employeeLogs, setEmployeeLogs] = useState([]);
  console.log("employeeLog", employeeLogs);
  const [emplyeeId, setEmployeeId] = useState("");

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  const fetchEmployeeLogs = async () => {
    setLoading(true);
    setApiError("");
    try {
      const response = await axios.get(`${apiUrl}/employee-logins`, {
        params: { department, empId },
      });
      if (Array.isArray(response.data)) {
        // setEmployeeLogs(response.data);
        setEmployeeLogs(response.data || []);
        setEmployeeId(response.data.empId);
      } else {
        setApiError("Failed to load employee logs.");
      }
    } catch (error) {
      setApiError("Error fetching employee logs.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployeeLogs();
  }, []);

  // const calculateMonthlyPerformance = () => {
  //   const groupedData = employeeLogs.reduce((acc, log) => {
  //     const employee = acc[log.employeeName] || { totalWork: 0, logs: [] };
  //     employee.totalWork += log.workProgress;
  //     employee.logs.push(log);
  //     acc[log.employeeName] = employee;
  //     return acc;
  //   }, {});

  //   return Object.keys(groupedData).map((employeeName) => ({
  //     name: employeeName,
  //     totalWork: groupedData[employeeName].totalWork,
  //     logs: groupedData[employeeName].logs,
  //   }));
  // };

  // const handleSearch = () => {
  //   fetchEmployeeLogs();
  // };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchEmployeeLogs();
  };

  const handleCancel = () => {
    setEmpId(" ");
    setDepartment("");
    // setFilteredEmployee("");
    // setEmployeeLogs([]);
    // setSelectedEmployee(null);
    setShowDetailed(null);
    setCurrentPage(1); // Reset to the first page
    fetchEmployeeLogs();
  };

  const handleShowDetails = (employee) => {
    setShowDetailed(employee);
    setEmployeeLogs(employee);
  };

  const handleHideDetails = () => {
    setShowDetailed(null);
    setEmployeeLogs(null);
    fetchEmployeeLogs();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const calculatePerformance = (loginTime, logoutTime) => {
  //   if (!loginTime || !logoutTime) return 0;

  //   const loginMoment = moment(loginTime);
  //   const logoutMoment = moment(logoutTime);

  //   if (!logoutMoment.isAfter(loginMoment)) return 0;

  //   const totalMinutesWorked = logoutMoment.diff(loginMoment, "minutes");
  //   const breakTime = 60;

  //   // Skip break time if total work is less than break time
  //   const netWorkMinutes = totalMinutesWorked > breakTime
  //     ? totalMinutesWorked - breakTime
  //     : totalMinutesWorked;

  //   const fullWorkMinutes = 480;
  //   const performance = Math.max((netWorkMinutes / fullWorkMinutes) * 100, 0);

  //   return performance.toFixed(2);
  // };

  const calculatePerformance = (loginTime, logoutTime) => {
    if (!loginTime || !logoutTime) return 0;

    // Parse login and logout times
    const loginMoment = moment(loginTime, "HH:mm:ss"); // Adjust format if needed
    const logoutMoment = moment(logoutTime, "HH:mm:ss");

    // Check if logout is after login
    if (!logoutMoment.isAfter(loginMoment)) return 0;

    // Calculate total work time in minutes
    const totalMinutesWorked = logoutMoment.diff(loginMoment, "minutes");

    // Subtract break time (default 1 hour or 60 minutes)
    const breakTime = 60; // in minutes
    const netWorkMinutes = totalMinutesWorked - breakTime;

    // Full workday (8 hours = 480 minutes)
    const fullWorkMinutes = 480;

    // Calculate performance percentage
    const performance = Math.max((netWorkMinutes / fullWorkMinutes) * 100, 0);

    return performance.toFixed(2); // Round to 2 decimal places
  };

  // Generate pie chart data for the selected employee
  const pieData = showDetailed
    ? {
        labels: ["Completed Work", "Remaining Work"],
        datasets: [
          {
            label: "Performance Data",
            data: [
              calculatePerformance(
                showDetailed.loginTime,
                showDetailed.logoutTime
              ), // Completed work
              100 -
                calculatePerformance(
                  showDetailed.loginTime,
                  showDetailed.logoutTime
                ), // Remaining work
            ],
            backgroundColor: ["#36A2EB", "#FF6384"],
            hoverBackgroundColor: ["#36A2EB", "#FF6384"],
          },
        ],
      }
    : null;

  // const monthlyPieData = {
  //   labels: calculateMonthlyPerformance().map((employee) => employee.name),
  //   datasets: [
  //     {
  //       label: "Monthly Performance",
  //       data: calculateMonthlyPerformance().map(
  //         (employee) => employee.totalWork
  //       ),
  //       backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#4BC0C0"],
  //     },
  //   ],
  // };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = employeeLogs.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems = Array.isArray(employeeLogs)
    ? employeeLogs.slice(indexOfFirstItem, indexOfLastItem)
    : [];

    const totalItems = employeeLogs?.length || 0; // Safely handle null/undefined
const totalPages = Math.ceil(totalItems / itemsPerPage);

const pageNumbers = [];
for (let i = 1; i <= totalPages; i++) {
  pageNumbers.push(i);
}

  // Generate pagination items
  // const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(employeeLogs.length / itemsPerPage); i++) {
  //   pageNumbers.push(i);
  // }

  return (
    <Container
      fluid
      className="border shadow p-4"
      style={{ backgroundColor: "aliceblue" }}
    >
      <h1 className="registration-heading mt-3">Performance Report</h1>

      <Breadcrumb>
        <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Performance Report</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-end ">
        <Button
          onClick={() => navigate("/hr-dashboard")}
          style={{ backgroundColor: "#f8701b" }}
        >
          Back to Hr Dashboard
        </Button>
      </div>

      {/* Filter Form */}
      <Card className="border shadow p-4 mb-4 mt-4">
        <h4 style={{ color: "#f8701b" }}>Filter</h4>
        <Form className="mb-4">
          {error && <Alert variant="danger">{error}</Alert>}
          <Row className="align-items-center">
            <Col xs={12} md={4} className="mb-2">
              <Form.Group controlId="filterEmpId">
                <Form.Label>Employee ID</Form.Label>
                <Form.Control
                  type="text"
                  value={empId}
                  onChange={(e) => setEmpId(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4} className="mb-2">
              <Form.Group controlId="filterDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control
                  as="select"
                  id="department"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                >
                  <option value="">Select Department</option>
                  {departmentOptions.map((dept, idx) => (
                    <option key={idx} value={dept}>
                      {dept}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4} sm={12} className="text-md-end mt-2 mt-md-0">
              <Button
                className="me-2"
                onClick={handleSearch}
                style={{ backgroundColor: "#f8701b" }}
              >
                Search
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      {showDetailed ? (
        <div className="d-flex justify-content-end">
          <Button
            style={{ backgroundColor: "#f8701b" }}
            className="mt-3"
            onClick={handleHideDetails}
          >
            Back to Table
          </Button>
        </div>
      ) : (
        <></>
      )}

      {/* Render Pie Chart or Table */}
      {showDetailed ? (
        <Card className="boarder shadow p-4 mb-4 mt-3">
          <h1 className="registration-heading mt-3">Performance Details</h1>
          <div className="d-flex justify-content-center">
            <Pie data={pieData} className="performance-pie-chart small-chart" />
          </div>
        </Card>
      ) : (
        <Card className="boarder shadow p-4">
          <h4 style={{ color: "#f8701b" }}>Daily Employee Performance</h4>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Employee ID</th>
                <th>Total Performance</th>
                <th>Performance Details</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentItems) &&
                currentItems.map((employee, index) => {
                  const performance = calculatePerformance(
                    employee.loginTime,
                    employee.logoutTime
                  );

                  return (
                    <tr key={employee.id}>
                      <td>{index + 1 + indexOfFirstItem}</td>
                      <td>{employee.empId}</td>
                      <td>{performance}%</td>
                      <td>
                        <Button
                          style={{ backgroundColor: "#f8701b" }}
                          onClick={() => handleShowDetails(employee)}
                        >
                          Show
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Card>
            <h4 style={{ color: "#f8701b" }}>
              Monthly Performance Details click here ---
            </h4>
            <div className="d-flex justify-content-end ">
              <Button
                onClick={() => navigate("/monthly-performance")}
                style={{ backgroundColor: "#f8701b" }}
              >
                Monthly performance
              </Button>
            </div>
          </Card>

          {/* Pagination */}
          <Pagination className="d-flex justify-content-end">
            <Pagination.Prev
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
            />
            {pageNumbers.map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => handlePageChange(number)}
              >
                {number}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                )
              }
            />
          </Pagination>
        </Card>
      )}
    </Container>
  );
};

export default PerformanceReport;
