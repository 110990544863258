import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Container,
  ProgressBar,
  Breadcrumb,
  Card,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";

const EmployeeProfile = () => {
  const { id } = useParams() || {};
  const [step, setStep] = useState(1); // Step for the stepper form
  const [progress, setProgress] = useState(33);
  const [formData, setFormData] = useState({
    employeeName: "",
    email: "",
    contactNumber: "",
    gender: "",
    dob: "",
    // address: "",
    jobTitle: "",
    empId: "",
    department: "",
    manager: "",
    joiningDate: "",
    education: "",
    bloodGroup: "",
    permanentAddress: "",
    currentAddress: "",
    familyMemberName: "",
    relation: "",
    emergencyContactNumber: "",
    annualCTC: null,
    officialEmail: "",
    password: "",
    accountNumber: null,
    ifscCode: null,
    branchName: null,
    branchAddress: null,
    monthlySalary: null,
    // passbook: null,
  });

  const designationOptions = [
    "Business Development Executive",
    "Business Development Manager",
    "Regional Manager",
    "Operational Manager",
    "Business Head",
    "Project Manager",
    "Assistant Manager",
    "HR Executive",
    "Trainer",
    "Accountant",
    "Web developer",
    "Android Developer",
    "AWS Engineer",
    "IT- Trainer",
    "UI/UX Designer",
    "Marketing Manager",
    "Chief Executive officer",
    "Managing Director",
  ];
  const departmentOptions = [
    "Mayu Sport Academy",
    "Mayu IT Planet",
    "Mayu E Learning",
    "Mayu TV",
    "Mayu E-Governance",
    "Mayu Business Service Group Of India",
  ];
  const managerOptions = [
    "Business Development Executive",
    "Business Development Manager",
    "Relational Manager",
    "Operational Manager",
    "Business Head",
    "Project Manager",
    "Assistant Manager",
    "HR Executive",
    "Trainer",
    "Accountant",
    "Web developer",
    "Android Developer",
    "AWS Engineer",
    "HR Manager",
    "CEO",
    "MD",
    "Customer Support Associate",
  ];
  const ctcOptions = [
    "1.80 LPA",
    "1.50 LPA",
    "2.40 LPA",
    "3.00LPA",
    "4.20 LPA",
    "6.00 LPA",
  ];
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    if (id) {
      const fetchEmployeeData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/employees/${id}`);

          let employeeData = response.data;

          if (employeeData.dob) {
            employeeData.dob = moment(employeeData.dob).format("YYYY-MM-DD");
          }

          if (employeeData.joiningDate) {
            employeeData.joiningDate = moment(employeeData.joiningDate).format(
              "YYYY-MM-DD"
            );
          }

          setFormData(employeeData);
        } catch (error) {
          Swal.fire("Error!", "Could not fetch employee data.", "error");
        }
      };

      fetchEmployeeData();
    }
  }, [id]);

  const validateForm = () => {
    // let formErrors = {};
    // let isValid = true;
    // if (step === 1) {
    //   if (!formData.employeeName) formErrors.employeeName = "Employee Name is required";
    //   if (!formData.email) formErrors.email = "Email is required";
    //   if (!formData.contactNumber) formErrors.contactNumber = "Contact Number is required";
    //   if (!formData.gender) formErrors.gender = "Gender is required";
    //   if (!formData.dob) formErrors.dob = "Date of Birth is required";
    //   if (!formData.bloodGroup)
    //     formErrors.bloodGroup = "Blood Group is required";
    //   if (!formData.permanentAddress)
    //     formErrors.permanentAddress = "Permanent Address is required";
    //   if (!formData.currentAddress)
    //     formErrors.currentAddress = "Current Address is required";
    //   if (!formData.familyMemberName)
    //     formErrors.familyMemberName = "Family Member Name is required";
    //   if (!formData.emergencyContactNumber)
    //     formErrors.emergencyContactNumber = "Emergency Contact Number is required";
    //   if (!formData.relation)
    //     formErrors.relation = "Relation is required";
    //   if (!formData.address)
    //     formErrors.address = "Address is required";
    //   if (!formData.education)
    //     formErrors.education = "Education is required";
    // }
    // if(step === 2){
    //   if (!formData.jobTitle)
    //     formErrors.jobTitle = "Job Title is required";
    //    if (!formData.empId)
    //     formErrors.empId = "Employee Id is required";
    //   if (!formData.department)
    //     formErrors.department = "Department is required";
    //   if (!formData.team)
    //     formErrors.team = "Team is required";
    //   if (!formData.manager)
    //     formErrors.manager = "Manager is required";
    //   if (!formData.joiningDate)
    //     formErrors.joiningDate = "Joining Date is required";
    //   if (!formData.annualCTC)
    //     formErrors.annualCTC = "Annual CTC is required";
    //   if (!formData.officialEmail)
    //     formErrors.officialEmail = "Official Email is required";
    //   if (!formData.password)
    //       formErrors.password = "Password is required";
    // }
    // if(step === 3){
    //   if (!formData.accountNumber)
    //     formErrors.accountNumber = "Account Number is required";
    // if (!formData.ifscCode)
    //     formErrors.ifscCode = "ifsc Code is required";
    // if (!formData.branchName)
    //       formErrors.branchName = "Branch Name is required";
    //   if (!formData.branchAddress)
    //         formErrors.branchAddress = "Branch Address is required";
    // }
    // if (!formData.employeeName) {
    //   formErrors.employeeName = "Employee Name is required";
    //   isValid = false;
    // }
    // if (!formData.email) {
    //   formErrors.email = "Email is required";
    //   isValid = false;
    // }
    // if (!formData.contactNumber) {
    //   formErrors.contactNumber = "Contact Number is required";
    //   isValid = false;
    // }
    // if (!formData.gender) {
    //   formErrors.gender = "gender is required";
    //   isValid = false;
    // }
    // if (!formData.dob) {
    //   formErrors.dob = "Date Of Birth is required";
    //   isValid = false;
    // }
    // if (!formData.address) {
    //   formErrors.address = "Address is required";
    //   isValid = false;
    // }
    // if (!formData.education) {
    //   formErrors.education = "Education is required";
    //   isValid = false;
    // }
    //  if (!formData.jobTitle) {
    //   formErrors.jobTitle = "Job Title is required";
    //   isValid = false;
    // }
    //  if (!formData.empId) {
    //   formErrors.empId = "Employee Id is required";
    //   isValid = false;
    // }
    // if (!formData.department) {
    //   formErrors.department = "Department is required";
    //   isValid = false;
    // }
    // if (!formData.team) {
    //   formErrors.team = "Team is required";
    //   isValid = false;
    // }
    // if (!formData.manager) {
    //   formErrors.manager = "Manager is required";
    //   isValid = false;
    // }
    // if (!formData.joiningDate) {
    //   formErrors.joiningDate = "Joining Date is required";
    //   isValid = false;
    // }
    // if (!formData.bloodGroup) {
    //   formErrors.bloodGroup = "Blood Group is required";
    //   isValid = false;
    // }
    // if (!formData.permanentAddress) {
    //   formErrors.permanentAddress = "Permanent Address is required";
    //   isValid = false;
    // }
    // if (!formData.currentAddress) {
    //   formErrors.currentAddress = "Current Address is required";
    //   isValid = false;
    // }
    // if (!formData.familyMemberName) {
    //   formErrors.familyMemberName = "Family Member Name is required";
    //   isValid = false;
    // }
    // if (!formData.emergencyContactNumber) {
    //   formErrors.emergencyContactNumber = "Emergency Contact Number is required";
    //   isValid = false;
    // }
    // if (!formData.relation) {
    //   formErrors.relation = "Relation is required";
    //   isValid = false;
    // }
    // if (!formData.annualCTC) {
    //   formErrors.annualCTC = "Annual CTC is required";
    //   isValid = false;
    // }
    // if (!formData.officialEmail) {
    //   formErrors.officialEmail = "Official Email is required";
    //   isValid = false;
    // }
    // if (!formData.password) {
    //     formErrors.password = "Password is required";
    //     isValid = false;
    //   }
    // if (!formData.accountNumber) {
    //     formErrors.accountNumber = "Account Number is required";
    //     isValid = false;
    //   }
    // if (!formData.ifscCode) {
    //     formErrors.ifscCode = "ifsc Code is required";
    //     isValid = false;
    //   }
    // if (!formData.branchName) {
    //       formErrors.branchName = "Branch Name is required";
    //       isValid = false;
    //     }
    //   if (!formData.branchAddress) {
    //         formErrors.branchAddress = "Branch Address is required";
    //         isValid = false;
    //       }
    // setErrors(formErrors);
    // return isValid;
    // return Object.keys(formErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "annualCTC") {
      // Remove 'LPA' or any non-numeric characters
      const cleanedValue = parseFloat(value.replace(/[^0-9.]/g, ""));
      const monthlySalary = cleanedValue
        ? ((cleanedValue * 100000) / 12).toFixed(2) // Assuming LPA is in lakhs
        : "";

      setFormData((prev) => ({
        ...prev,
        [name]: cleanedValue, // Save only the numeric value
        monthlySalary,
      }));
    } else if (name === "passbook") {
      const file = e.target.files[0];
      setFormData((prev) => ({
        ...prev,
        [name]: file || null,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "passbook") {
  //     const file = e.target.files[0];
  //     setFormData((prev) => ({
  //       ...prev,
  //       [name]: file || null,
  //     }));
  //   } else {
  //     setFormData((prev) => ({
  //       ...prev,
  //       [name]: value,
  //     }));
  //   }
  // };

  const handleNext = () => {
    // if (validateForm()) {
    setStep(step + 1);
    setProgress((step + 1) * 33);
    // }
  };

  const handlePrevious = () => {
    setStep(step - 1);
    setProgress((step - 1) * 33);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare form data for file upload
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        // Add only if value is not null
        data.append(key, formData[key]);
      }
    });
    try {
      const endpoint = id ? `${apiUrl}/employees/${id}` : `${apiUrl}/employee`;
      const method = id ? "put" : "post";

      const response = await axios[method](endpoint, formData);

      Swal.fire(
        "Success",
        `Employee ${id ? "updated" : "added"} successfully`,
        "success"
      );
      navigate("/employee-info");
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Operation failed";
      Swal.fire("Error", errorMessage, "error");
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${apiUrl}/employee`, formData, {
  //       headers: { "Content-Type": "application/json" },
  //     });
  //     Swal.fire("Success", "Employee added successfully", "success");
  //   } catch (error) {
  //     console.error(error);
  //     Swal.fire("Error", error.response?.data?.message || "Failed to add employee", "error");
  //   }
  // };

  return (
    <Container>
      <div
        className="border shadow p-4"
        style={{ backgroundColor: "aliceblue" }}
      >
        <h1 className="registration-heading mt-3">Employee Profile</h1>
        <div className="d-flex justify-content-end ">
          <Button
            onClick={() => navigate("/hr-dashboard")}
            style={{ backgroundColor: "#f8701b" }}
          >
            Back to HR Dashboard
          </Button>
        </div>
        <Breadcrumb>
          <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Employee Profile</Breadcrumb.Item>
        </Breadcrumb>
        <ProgressBar now={progress} className="mb-4 boarder-1" />

        <Form onSubmit={handleSubmit}>
          <Card className="p-3">
            {step === 1 && (
              <>
                <h4>Personal Information</h4>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="employeeName">
                      <Form.Label>
                        Employee (First, Middle, Last) Name
                        <span className="text-danger">*</span>
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="employeeName"
                        value={formData.employeeName}
                        onChange={handleInputChange}
                        isInvalid={!!errors.employeeName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.employeeName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="email">
                      <Form.Label>
                        Personal Email<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="gender">
                      <Form.Label>
                        Gender<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="gender"
                        value={formData.gender}
                        onChange={handleInputChange}
                        isInvalid={!!errors.gender}
                      >
                        <option value="">Select...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.gender}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="contactNumber">
                      <Form.Label>
                        Contact Number<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="tel"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                        isInvalid={!!errors.contactNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.contactNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="education">
                      <Form.Label>
                        Education<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="education"
                        value={formData.education}
                        onChange={handleInputChange}
                        isInvalid={!!errors.education}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.education}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="dob">
                      <Form.Label>
                        Date of Birth<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="dob"
                        value={formData.dob}
                        onChange={handleInputChange}
                        isInvalid={!!errors.dob}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dob}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="bloodGroup">
                      <Form.Label>
                        Blood Group<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        name="bloodGroup"
                        value={formData.bloodGroup}
                        onChange={handleInputChange}
                        isInvalid={!!errors.bloodGroup}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.bloodGroup}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="permanentAddress">
                      <Form.Label>
                        Permanent Address<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        name="permanentAddress"
                        value={formData.permanentAddress}
                        onChange={handleInputChange}
                        isInvalid={!!errors.permanentAddress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.permanentAddress}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="currentAddress">
                      <Form.Label>
                        Current Address
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        name="currentAddress"
                        value={formData.currentAddress}
                        onChange={handleInputChange}
                        isInvalid={!!errors.currentAddress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.currentAddress}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="familyMemberName">
                      <Form.Label>
                        Family Member Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        name="familyMemberName"
                        value={formData.familyMemberName}
                        onChange={handleInputChange}
                        isInvalid={!!errors.familyMemberName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.familyMemberName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="relation">
                      <Form.Label>
                        Relation<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        name="relation"
                        value={formData.relation}
                        onChange={handleInputChange}
                        isInvalid={!!errors.relation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.relation}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="emergencyContactNumber">
                      <Form.Label>
                        Emergency Contact Number
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        name="emergencyContactNumber"
                        value={formData.emergencyContactNumber}
                        onChange={handleInputChange}
                        isInvalid={!!errors.emergencyContactNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emergencyContactNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Button variant="secondary" onClick={handlePrevious} className="mt-3 me-2">
              Previous
            </Button> */}
                <div className="mt-3">
                  <Button variant="primary" onClick={handleNext}>
                    Next
                  </Button>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <h4>Professional Information</h4>
                <Row className="mt-3">
                  <Col md={4}>
                    <Form.Group controlId="jobTitle">
                      <Form.Label>
                        Designation<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleInputChange}
                        isInvalid={!!errors.jobTitle}
                      >
                        <option value="">Select Designation</option>
                        {designationOptions.map((designation, index) => (
                          <option key={index} value={designation}>
                            {designation}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.jobTitle}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="empId">
                      <Form.Label>
                        Employee ID<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="empId"
                        value={formData.empId}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="department">
                      <Form.Label>
                        Project/Department<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="department"
                        value={formData.department}
                        onChange={handleInputChange}
                        isInvalid={!!errors.department}
                      >
                        <option value="">Select Project/Department</option>
                        {departmentOptions.map((department, index) => (
                          <option key={index} value={department}>
                            {department}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.department}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <Form.Group controlId="officialEmail">
                      <Form.Label>
                        Official Email<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="officialEmail"
                        value={formData.officialEmail}
                        onChange={handleInputChange}
                        isInvalid={!!errors.officialEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.officialEmail}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="manager">
                      <Form.Label>
                        Reporting Manager<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="manager"
                        value={formData.manager}
                        onChange={handleInputChange}
                        isInvalid={!!errors.manager}
                      >
                        <option value="">Select Reporting Manager</option>
                        {managerOptions.map((manager, index) => (
                          <option key={index} value={manager}>
                            {manager}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.manager}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="password">
                      <Form.Label>
                        Create Password<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="joiningDate">
                      <Form.Label>
                        Joining Date<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="joiningDate"
                        value={formData.joiningDate}
                        onChange={handleInputChange}
                        isInvalid={!!errors.joiningDate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.joiningDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="annualCTC">
                      <Form.Label>
                        Annual CTC<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="annualCTC"
                        value={formData.annualCTC}
                        onChange={handleInputChange}
                        isInvalid={!!errors.annualCTC}
                      >
                        <option value="">Select Annual CTC</option>
                        {ctcOptions.map((ctc, index) => (
                          <option key={index} value={ctc}>
                            {ctc}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.annualCTC}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="monthlySalary">
                      <Form.Label>Monthly Salary (in Thousands)</Form.Label>
                      <Form.Control
                        type="text"
                        name="monthlySalary"
                        value={formData.monthlySalary}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    variant="secondary"
                    onClick={handlePrevious}
                    className="me-3"
                  >
                    Previous
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleNext}
                    className="me-3"
                  >
                    Next
                  </Button>
                  <Button variant="success" type="submit">
                    Submit
                  </Button>
                </div>
              </>
            )}

            {step === 3 && (
              <>
                <h4>Bank Account Details</h4>
                <Row className="mt-3">
                  <Col md={6}>
                    <Form.Group controlId="accountNumber">
                      <Form.Label>
                        Account Number<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="ifscCode">
                      <Form.Label>
                        IFSC Code<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="ifscCode"
                        value={formData.ifscCode}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <Form.Group controlId="branchName">
                      <Form.Label>
                        Branch Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="branchName"
                        value={formData.branchName}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="branchAddress">
                      <Form.Label>
                        Branch Address<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="branchAddress"
                        value={formData.branchAddress}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <Form.Group controlId="passbook">
                      <Form.Label>Upload Passbook</Form.Label>
                      <Form.Control
                        type="file"
                        name="passbook"
                        onChange={handleInputChange}
                        accept=".pdf, .jpg, .jpeg, .png"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    variant="secondary"
                    onClick={handlePrevious}
                    className="me-3"
                  >
                    Previous
                  </Button>
                  <Button variant="success" type="submit" className="">
                    Submit
                  </Button>
                </div>
              </>
            )}
          </Card>
        </Form>
      </div>
    </Container>
  );
};

export default EmployeeProfile;