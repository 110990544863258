import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  Container,
  Breadcrumb,
  Card,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import moment from "moment";

const EmployeeLogTable = () => {
  const [employeeLogs, setEmployeeLogs] = useState([]);
  console.log("employeelog", employeeLogs);
  const [filters, setFilters] = useState({
    // date: "",
    empId: "",
    employeeName: "",
    // department: "",
  });
  const departmentOptions = [
    "Mayu Sport Academy",
    "Mayu IT Planet",
    "Mayu E Learning",
    "Mayu TV",
    "Mayu E-Governance",
    "Mayu Business Service Group Of India",
  ];
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost
    ? "http://localhost:5000/api" 
    : 'https://www.mayuhrsolutions.com/api';

  const fetchEmployeeLogs = async () => {
    setLoading(true);
    setApiError("");
    try {
      const response = await axios.get(`${apiUrl}/employee-logins`, {
        params: filters,
      });
      if (Array.isArray(response.data)) {
        setEmployeeLogs(response.data);
      } else {
        setApiError("Failed to load employee logs.");
      }
    } catch (error) {
      setApiError("Error fetching employee logs.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployeeLogs();
  }, []);

  const handleSearch = () => {
    fetchEmployeeLogs();
  };

  const handleCancel = () => {
    setFilters({ empId: "", employeeName: "", department: "" });
    fetchEmployeeLogs();
    
  };

  return (
    <Container>
      <h2 className="mt-3" style={{ color: "#f8701b" }}>Daily Attendance</h2>
      <Breadcrumb>
        <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Daily Attendance</Breadcrumb.Item>
      </Breadcrumb>
      <div className="border shadow p-4" style={{backgroundColor:"#78b7f6"}}>
      <Card className="mt-4 p-4 ">
        <h4 style={{color:"#f8701b"}}>Filter</h4>
        <Form className="mb-4">
          {error && <Alert variant="danger">{error}</Alert>}
          <Row className="align-items-center">
            {/* <Col md={3} sm={12} className="mb-2 mb-md-0">
              <Form.Group controlId="filterDate">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  value={filters.date}
                  onChange={(e) =>
                    setFilters({ ...filters, date: e.target.value })
                  }
                />
              </Form.Group>
            </Col> */}
            <Col md={4} sm={12} className="mb-2 mb-md-0">
              <Form.Group controlId="filterEmpId">
                <Form.Label>Employee ID</Form.Label>
                <Form.Control
                  type="text"
                  value={filters.empId}
                  onChange={(e) =>
                    setFilters({ ...filters, empId: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={4} sm={12} className="mb-2 mb-md-0">
              <Form.Group controlId="filterEmployeeName">
                <Form.Label>Employee Name</Form.Label>
                <Form.Control
                  type="text"
                  
                  value={filters.employeeName}
                  onChange={(e) =>
                    setFilters({ ...filters, employeeName: e.target.value })
                  }
                >
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4} sm={12} className="mb-2 mb-md-0">
              <Form.Group controlId="filterDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control
                  as="select"
                  value={filters.department}
                  onChange={(e) =>
                    setFilters({ ...filters, department: e.target.value })
                  }
                >
                  <option value="">Select Department</option>
                  {departmentOptions.map((dept, idx) => (
                    <option key={idx} value={dept}>{dept}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
           <Row className="mt-4">
            <Col md={4} sm={12} className="mb-2 mb-md-0">
            <Button className="me-2" onClick={handleSearch} style={{backgroundColor:"#f8701b"}}>
                Search
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
           </Row>
             
           
          </Row>
        </Form>
      </Card>
      <Card className="mt-5">
        <h4 className="mt-3 p-3" style={{color:"#f8701b"}}>Employee Attendance</h4>
        <div className="table-responsive">
        <Table striped bordered hover responsive>
          <thead style={{color:"#f8701b"}}>
            <tr>
              <th>Sr. No.</th>
              {/* <th>Name</th>
              <th>Department</th> */}
              <th>EmpId</th>
              <th>Login Time</th>
              <th>Logout Time</th>
              {/* <th>Date</th> */}
              <th>Work Progress (%)</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="7" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : apiError ? (
              <tr>
                <td colSpan="7" className="text-center text-danger">
                  {apiError}
                </td>
              </tr>
            ) : employeeLogs.length > 0 ? (
              employeeLogs.map((log, index) => (
                <tr key={log.srNo || index}>
                  <td>{log.srNo || index + 1}</td>
                  {/* <td>{log.employeeName}</td>
                  <td>{log.department}</td> */}
                  <td>{log.empId}</td>
                  <td>{log.loginTime ? moment(log.loginTime).format("hh:mm A") : "N/A"}</td>
                  <td>{log.logoutTime ? moment(log.logoutTime).format("hh:mm A") : "N/A"}</td>
                  {/* <td>{log.date ? moment(log.date).format("YYYY-MM-DD") : "N/A"}</td> */}
                  <td>{log.workProgress !== undefined ? log.workProgress.toFixed(2) + "%" : "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        </div>

      </Card>
      </div>
    </Container>
  );
};

export default EmployeeLogTable;
