import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Breadcrumb,
  Pagination,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AdminDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end index for slicing employees data based on currentPage
  const indexOfLastEmployee = currentPage * perPage;
  const indexOfFirstEmployee = indexOfLastEmployee - perPage;
  const currentJobs = jobs.slice(indexOfFirstEmployee, indexOfLastEmployee);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(jobs.length / perPage);

  // Check if the app is running on localhost (development)
  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    // Fetch job listings from the correct API endpoint
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${apiUrl}/jobs`);

        if (!response.ok) {
          throw new Error("Failed to fetch jobs");
        }

        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  const handleJobDelete = async (jobId) => {
    // Show a confirmation dialog before deletion
    const confirmDeletion = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmDeletion.isConfirmed) {
      try {
        // Proceed with the deletion
        await fetch(`${apiUrl}/jobs/${jobId}`, {
          method: "DELETE",
        });

        // Refresh job listings
        const response = await fetch(`${apiUrl}/jobs`);
        const data = await response.json();
        setJobs(data);

        // Show success message
        Swal.fire({
          title: "Deleted!",
          text: "Your job has been deleted.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(error);
        // Show error message
        Swal.fire({
          title: "Error!",
          text: "There was a problem deleting the job. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleShowApplications = (jobId) => {
    if (jobId) {
      navigate(`/applications/${jobId}`); // Pass the jobId as a URL parameter
    } else {
      console.error("Job ID is undefined or invalid");
    }
  };

  return (
    <Container
      className="border shadow p-4"
      style={{ backgroundColor: "aliceblue" }}
    >
      <h1 className="registration-heading mt-3">Admin Dashboard</h1>
      <Breadcrumb>
        <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Admin Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-end ">
        <Button
          onClick={() => navigate("/hr-dashboard")}
          style={{ backgroundColor: "#f8701b" }}
        >
          Back to HR Dashboard
        </Button>
      </div>
      <>
        <h3 style={{ color: "#f8701b" }} className="text-center">
          Current Job Opening
        </h3>
        <Link to="/view-application" className="mt-3">
          <Button variant="primary">View Apllications</Button>
        </Link>
        <Link to="/add-job" className="d-flex justify-content-end">
          <Button variant="primary">Add New Job</Button>
        </Link>
        <div className="table-responsive">
          <Table className="mt-2 shadow" striped bordered hover>
            <thead>
              <tr>
                <th>Sr. no.</th>
                <th>Title</th>
                <th>Company</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentJobs.map((job, index) => (
                <tr key={job.id}>
                  <td>{job.srNo || index + 1 + indexOfFirstEmployee}</td>
                  <td>{job.title}</td>
                  <td>{job.companyName}</td>
                  <td>
                    <Link to={`/add-job/${job.id}`}>
                      <Button variant="primary" className="me-3">
                        Edit
                      </Button>
                    </Link>
                    <Button
                      style={{ backgroundColor: "#ff9b4a" }}
                      className="me-3"
                      onClick={() => handleJobDelete(job.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>

      <div className="d-flex justify-content-end">
      <Pagination>
  {/* Previous Button */}
  <Pagination.Prev
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  />

  {/* First Page */}
  {currentPage > 2 && (
    <>
      <Pagination.Item onClick={() => handlePageChange(1)}>
        1
      </Pagination.Item>
      {currentPage > 3 && <Pagination.Ellipsis />}
    </>
  )}

  {/* Current and Surrounding Pages */}
  {[...Array(totalPages)]
    .map((_, index) => index + 1)
    .filter(
      (page) =>
        page === currentPage || // Always show the current page
        page === currentPage - 1 || // One page before
        page === currentPage + 1 // One page after
    )
    .map((page) => (
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    ))}

  {/* Last Page */}
  {currentPage < totalPages - 1 && (
    <>
      {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
      <Pagination.Item onClick={() => handlePageChange(totalPages)}>
        {totalPages}
      </Pagination.Item>
    </>
  )}

  {/* Next Button */}
  <Pagination.Next
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  />
</Pagination>
      </div>
    </Container>
  );
};

export default AdminDashboard;
