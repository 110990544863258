import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const token = localStorage.getItem('token');

  // Check if token exists
  if (!token) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />; // Renders child routes if authorized
};

export default ProtectedRoute;
