import React, { useEffect, useState } from "react";
import { Container, Row, Col, ProgressBar, Card, Button } from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { FaCalendarCheck } from 'react-icons/fa';
import axios from "axios"; 

const EmployeeDashboard = () => {
  const { id } = useParams();
  const [loginTime, setLoginTime] = useState("");
  const [logoutTime, setLogoutTime] = useState("06:00 PM"); 
  const [progress, setProgress] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const apiUrl =
    window.location.hostname === "localhost"
      ? "http://localhost:5000/api"
      : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    if (id) {
      localStorage.setItem("empId", id);
    }
  }, [id]);

  const handleLogin = () => {
    axios.post(`${apiUrl}/login-employee`, { empId: id })
      .then(response => {
        const loginTime = moment().format("hh:mm A");
        setLoginTime(loginTime);
        setIsLoggedIn(true);
        
        localStorage.setItem("loginTime", loginTime);
        localStorage.setItem("loginDate", moment().format("YYYY-MM-DD"));

        Swal.fire("Success", response.data.message || "Clocked in successfully!", "success");
      })
      .catch(error => {
        console.error("Login failed:", error);
        Swal.fire("Error", "Login failed", "error");
      });
  };

  const handleLogout = () => {
    axios.post(`${apiUrl}/logout`, { empId: id }).then(response => {
      const { logoutTime } = response.data;
      setLogoutTime(moment(logoutTime).format("hh:mm A"));
      setIsLoggedIn(false);
      localStorage.removeItem("loginTime");
      Swal.fire("Success", response.data.message || "Clocked out successfully!", "success");
    }).catch(error => {
      Swal.fire("Error", "Logout failed", "error");
    });
  };

  useEffect(() => {
    const storedLoginTime = localStorage.getItem("loginTime");
    if (storedLoginTime) {
      setLoginTime(storedLoginTime); // Directly setting storedLoginTime
      setIsLoggedIn(true); // Mark as logged in if there's a login time in storage
    }
  }, []);

  useEffect(() => {
    const updateProgress = () => {
      const now = moment();
      const start = moment("09:00 AM", "hh:mm A");
      const end = moment("06:00 PM", "hh:mm A");

      const totalMinutes = end.diff(start, "minutes");
      const elapsedMinutes = now.diff(start, "minutes");

      const progressPercentage = Math.min((elapsedMinutes / totalMinutes) * 100, 100);
      setProgress(progressPercentage);
    };

    const interval = setInterval(updateProgress, 1000);
    return () => clearInterval(interval);
  }, []);

  const getProgressBarVariant = () => {
    if (progress < 50) return "warning"; 
    if (progress < 100) return "success";
    return "danger";
  };

  const handleCardClick = (path) => {
    const empId = localStorage.getItem("empId"); 
    if (empId) {
      navigate(`${path}/${empId}`); 
    } else {
      Swal.fire("Error", "Employee ID not found", "error");
    }
  };

  return (
    <Container className="mt-5">
      <div>
        <Row>
          <Col md={4} className="mb-4">
            <Card onClick={() => handleCardClick("/employee-details")} className="text-white" style={{ backgroundColor: "rgb(145, 14, 14)" }}>
              <Card.Body className="d-flex align-items-center">
                <FaCalendarCheck size={30} className="mr-3" />
                <Card.Title>Employee Profile</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-end align-item-end">
        <Row>
          <Col md={12} className="mx-auto">
            <Card className="shadow">
              <Card.Header className="text-center">
                <h4 style={{ color: "rgb(145, 14, 14)" }}>Employee TimeSheet</h4>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Col>
                    <h6 className="fs-5" style={{ color: "rgb(145, 14, 14)" }}>
                      Clock In Time: {loginTime || "Not clocked in"}
                      {!isLoggedIn && (
                        <Button 
                          variant="primary" 
                          size="sm" 
                          className="ml-3 p-2" 
                          onClick={handleLogin}
                        >
                          Clock In
                        </Button>
                      )}
                    </h6>
                    <br />
                    <h6 className="fs-5" style={{ color: "rgb(145, 14, 14)" }}>
                      Clock Out Time: {logoutTime}
                      {isLoggedIn && (
                        <Button 
                          variant="secondary" 
                          size="sm" 
                          className="ml-3 p-2" 
                          onClick={handleLogout}
                        >
                          Clock Out
                        </Button>
                      )}
                    </h6>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <h6 className="text-danger">Work Progress</h6>
                    <ProgressBar
                      now={progress}
                      label={`${Math.floor(progress)}%`}
                      variant={getProgressBarVariant()}
                      animated
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <p style={{ color: "rgb(145, 14, 14)" }}>
                      {progress < 100
                        ? `Keep up the good work! You're ${Math.floor(progress)}% through your day.`
                        : "Work day completed!"}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default EmployeeDashboard;
