// src/components/RegistrationForm.js

import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// Validation schema using yup
const schema = yup.object().shape({
  fullName: yup
    .string()
    .required("Full name is required")
    .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  contactNumber: yup
    .string()
    .required("Contact number is required")
    .matches(/^[0-9]{10}$/, "Must be a valid 10-digit number"),
  dob: yup.date().required("Date of birth is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  termsAccepted: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions"),
});

const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

const RegistrationForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const onSubmit = (data) => {
    axios
      .post(`${apiUrl}/auth/register`, data) // Corrected URL here
      .then((response) => {
        // Show SweetAlert success message
        Swal.fire({
          icon: "success",
          title: "Registration Successful",
          text: "You have successfully registered. Redirecting to OTP verification...",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/otp-verification"); // Replace with actual route
          }
        });

        // Clear form after submission
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Registration Failed",
          text:
            error.response?.data.message ||
            "There was an error during registration. Please try again.",
        });
      });
  };

  return (
    <div className="container">
      <h3 className="registration-heading mt-3">Registration Form</h3>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className=" mt-2 border shadow p-4 rounded"
      >
        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="formFullName">
              <Form.Label>
                Full Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter full name"
                {...register("fullName")}
              />
              {errors.fullName && (
                <p className="text-danger">{errors.fullName.message}</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>
                Email<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                {...register("email")}
              />
              {errors.email && (
                <p className="text-danger">{errors.email.message}</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="formContactNumber">
              <Form.Label>
                Contact Number<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter contact number"
                {...register("contactNumber")}
              />
              {errors.contactNumber && (
                <p className="text-danger">{errors.contactNumber.message}</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formDob">
              <Form.Label>
                Date of Birth<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control type="date" {...register("dob")} />
              {errors.dob && (
                <p className="text-danger">{errors.dob.message}</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="formPassword">
              <Form.Label>
                Password<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                {...register("password")}
              />
              {errors.password && (
                <p className="text-danger">{errors.password.message}</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label>
                Confirm Password<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm password"
                {...register("confirmPassword")}
              />
              {errors.confirmPassword && (
                <p className="text-danger">{errors.confirmPassword.message}</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="formTerms">
          <Form.Check
            type="checkbox"
            label="I accept the terms and conditions"
            {...register("termsAccepted")}
          />
          {errors.termsAccepted && (
            <p className="text-danger">{errors.termsAccepted.message}</p>
          )}
        </Form.Group>

        <div className="mt-3">
          <Button variant="primary" className="me-3" type="submit">
            Submit
          </Button>
          <Button variant="secondary" onClick={() => reset()}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default RegistrationForm;
