import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import HomePage from "./components/Home";
import MainHome from "./components/MainHome";
import DailyAttendance from "./components/DailyAttendence";
import PerformanceReport from "./components/PerformanceReport";
import PayrollSheet from "./components/PayrollSheet";
import LegalDocuments from "./components/LegalDocuments";
import EmployeeProfile from "./components/EmployeeProfile";
import Register from "./components/Register";
import LoginPage from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import OtpVerification from "./components/OtpVerification";
import EmployeeInfo from "./components/EmployeeInfo";
import AdminDashboard from "./components/AdminDashboard";
import JobListingPage from "./components/JobListingPage";
import AddJobPage from "./components/AddJobPage";
import ApplicationsPage from "./components/ApplicationPage";
import CandidateRegistration from "./components/CandidateRegistration";
import CandidateLogin from "./components/CandidateLogin";
import OtpVerifyCandidate from "./components/OtpVerifycandidate";
import CandidateDashboard from "./components/CandidateDashboard";
import ViewApplication from "./components/ViewApplication";
import JobApplicationForm from "./components/JobApplicationForm";
// import SelectedCandidate from './components/selectedCandidate';
import CustomNavbar from "./components/Navbar";
// import Sidebar from './components/Sidebar';
import Footer from "./components/Footer";
import CandidateForgotPassword from "./components/CandidateForgotPassword";
import OfferLetterForm from "./components/OfferLetterPage";
import NotFound from "./components/NotFound";
import EmployeeLogin from "./components/EmployeeLogin";
import EmployeeForgotPassword from "./components/EmployeeForgotPassword";
import axios from "axios";
import EmployeeDashboard from "./components/EmployeeDashboard";
import EmployeeDetailsPage from "./components/EmployeeDetailPage";
import ExportButton from "./components/ExportButton";

function App() {
  const [userData, setUserData] = useState(null);
  console.log("user==", userData);
  // const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  console.log("role", userRole);
  const [users, setUsers] = useState(null);
  console.log("----->", users);

  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost
    ? "http://localhost:5000/api"
    : 'https://www.mayuhrsolutions.com/api';

  useEffect(() => {
    // Fetch all users from the API when the component mounts
    const fetchAllUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/auth/users`); // Adjust the endpoint as necessary
        setUsers(response.data);
        setUserRole(response.data[0].role);
        console.log("Fetched users:", response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchAllUsers();
  }, []);

  return (
    <Router>
      <div id="root" className="d-flex flex-column min-vh-100">
        <CustomNavbar />
        <Container fluid className="d-flex flex-grow-1">
          <Row className="flex-grow-1">
            {/* Render Sidebar only for HR users */}
            {/* {userRole && userRole.toUpperCase()  === 'HR' && (
              <Col md={3} className="sidebar-col">
                <Sidebar/>
              </Col>
            )} */}

            {/* Main Content Area */}
            <Col
              md={12}
              // md={userRole && userRole.toUpperCase() === 'HR' ? 9 : 12}
              className="content-col"
              style={{ backgroundColor: " rgb(212, 224, 230)" }}
            >
              <Routes>
                {/* Routes for HR */}
                {/* {userData === 'HR' && ( */}
                <>
                  <Route path="/hr-dashboard" element={<HomePage />} />
                  <Route
                    path="/daily-attendance"
                    element={<DailyAttendance />}
                  />
                  <Route path="/payroll-sheet" element={<PayrollSheet />} />
                  <Route path="/legal-documents" element={<LegalDocuments />} />
                  <Route
                    path="/employee-profile"
                    element={<EmployeeProfile />}
                  />
                  <Route
                    path="/employee-profile/:id"
                    element={<EmployeeProfile />}
                  />
                  <Route path="/employee-info" element={<EmployeeInfo />} />
                  <Route
                    path="/performance-report"
                    element={<PerformanceReport />}
                  />
                  <Route path="/admin-dashboard" element={<AdminDashboard />} />
                  <Route
                    path="/view-application"
                    element={<ViewApplication />}
                  />
                  {/* <Route path="/selected-candidate" element={<SelectedCandidate />} /> */}
                  <Route path="/add-job" element={<AddJobPage />} />
                  <Route path="/add-job/:id" element={<AddJobPage />} />
                  <Route
                    path="/applications/:jobId"
                    element={<ApplicationsPage />}
                  />
                  <Route path="/" element={<MainHome />} />
                  <Route path="/career" element={<JobListingPage />} />
                  <Route
                    path="/employee-details/:id"
                    element={<EmployeeDetailsPage />}
                  />
                  <Route
                    path="/apply/:jobId"
                    element={<JobApplicationForm />}
                  />
                  <Route path="/hr-register" element={<Register />} />
                  <Route path="/hr-login" element={<LoginPage />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/otp-verification"
                    element={<OtpVerification />}
                  />
                  <Route
                    path="/registration"
                    element={<CandidateRegistration />}
                  />
                  <Route
                    path="/otp-verify-candidate"
                    element={<OtpVerifyCandidate />}
                  />
                  <Route path="/login" element={<CandidateLogin />} />
                  <Route
                    path="/candidate-forgot-password"
                    element={<CandidateForgotPassword />}
                  />
                  <Route path="/dashboard" element={<CandidateDashboard />} />
                  <Route
                    path="/offer-letter-form/:jobId"
                    element={<OfferLetterForm />}
                  />
                  <Route path="/employee-login" element={<EmployeeLogin />} />
                  <Route
                    path="/forgot-password-employee"
                    element={<EmployeeForgotPassword />}
                  />
                  <Route
                    path="/employee-dashboard/:id"
                    element={<EmployeeDashboard />}
                  />
                  <Route path="*" element={<NotFound />} />
                  <Route path="/export-button" element={<ExportButton />} />

                </>
                {/* )} */}
              </Routes>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
