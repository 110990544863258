import React, { useEffect, useState } from 'react';
import { Table, Container, Spinner, Button, Modal, Form, Col, Row, Breadcrumb} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control modal
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Track employee to edit

  const navigate = useNavigate();
  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost ? "http://localhost:5000/api" : 'https://www.mayuhrsolutions.com/api';

  const fetchEmployees = async () => {
    try {
      const response = await fetch(`${apiUrl}/employees`);
      if (!response.ok) throw new Error('Failed to fetch employees');
      const data = await response.json();
      setEmployees(data);
    } catch (error) {
      setError(error.message);
      Swal.fire({ icon: 'error', title: 'Error!', text: error.message });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchEmployees();
  }, []);
  const handleDelete = async (id) => {
    const confirmDelete = await Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be undone.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });
    if (confirmDelete.isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}/employees/${id}`, { method: 'DELETE' });
        if (!response.ok) throw new Error('Failed to delete employee');
        Swal.fire('Deleted!', 'Employee has been deleted.', 'success');
        fetchEmployees(); // Refetch employees after deletion
      } catch (error) {
        Swal.fire({ icon: 'error', title: 'Error!', text: error.message });
      }
    }
  };

  const handleEdit = (employee) => {
    setSelectedEmployee(employee); // Set employee to edit
    setShowModal(true); // Show the modal
  };

  const handleEditEmployee = (employee) =>{
    navigate(`/employee-profile/${employee.id}`);
  }

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setSelectedEmployee(null); // Reset selected employee
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setSelectedEmployee((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateEmployee = async () => {
    try {
      const response = await fetch(`${apiUrl}/employees/${selectedEmployee.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedEmployee),
      });

      if (!response.ok) throw new Error('Failed to update employee');
      Swal.fire('Success!', 'Employee details updated.', 'success');
      fetchEmployees(); // Refresh employees list
      handleCloseModal(); // Close modal after success
    } catch (error) {
      Swal.fire({ icon: 'error', title: 'Error!', text: error.message });
    }
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <Breadcrumb>
        <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Employee Info</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Employee List</h2>
      <div className="table-responsive">
      {error && <p className="text-danger">{error}</p>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Job Title</th>
            <th>Department</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {employees.length > 0 ? (
            employees.map((employee) => (
              <tr key={employee.id}>
                <td>
                  <Button variant="link" onClick={() => handleEdit(employee)}>
                    {employee.empId}
                  </Button>
                </td>
                <td>{employee.employeeName}</td>
                <td>{employee.jobTitle}</td>
                <td>{employee.department}</td>
                <td>
                  <Button variant="primary" className="me-2" onClick={() => handleEditEmployee(employee)}>
                    Edit
                  </Button>
                  <Button variant="danger" onClick={() => handleDelete(employee.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">No employees found.</td>
            </tr>
          )}
        </tbody>
      </Table>
      </div>

      {/* Modal for Editing Employee */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEmployee && (
            <Form>
            <h4>Personal Details</h4>
            <Row>
              
              <Col md={4}>
                <Form.Group controlId="formEmployeeName"className="mt-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.employeeName}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        employeeName: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mt-3">
                  <Form.Label>Personal Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.email}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        email: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formContactNumber" className="mt-3">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="number"
                    value={selectedEmployee.contactNumber}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        contactNumber: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formGender" className="mt-3">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.gender}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        gender: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>

              
              <Col md={4}>
                <Form.Group controlId="formEducation" className="mt-3">
                  <Form.Label>Education</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.education}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        education: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formDateOfBirth" className="mt-3">
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.dob}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        dob: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formBloodGroup" className="mt-3">
                  <Form.Label>Blood Group</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.bloodGroup}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        bloodGroup: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formPermanentAddress" className="mt-3">
                  <Form.Label>Permanent Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.permanentAddress}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        permanentAddress: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>

              
              <Col md={4}>
                <Form.Group controlId="formCurrentAddress" className="mt-3">
                  <Form.Label>Current Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.currentAddress}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        currentAddress: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formFamilyMemberName" className="mt-3">
                  <Form.Label>Family Member Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.familyMemberName}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        familyMemberName: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formRelation" className="mt-3">
                  <Form.Label>Relation</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.relation}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        relation: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group
                  controlId="formEmergencyContactNumber"
                  className="mt-3"
                >
                  <Form.Label>Emergency Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.emergencyContactNumber}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        emergencyContactNumber: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <h4>Professional Details</h4>
           
            <Row className="mt-3">
              <Col md={4}>
                <Form.Group controlId="formDepartment"className="mt-3">
                  <Form.Label>Department</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.department}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        department: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formEmpId"className="mt-3">
                  <Form.Label>Employee ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.empId}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        empId: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              {/* <Col md={4}>
                <Form.Group controlId="formTeam"className="mt-3">
                  <Form.Label>Team</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.team}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        team: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col> */}
              <Col md={4}>
                <Form.Group controlId="formManager"className="mt-3">
                  <Form.Label>Reporting Manager</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.manager}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        manager: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

           
            <Row className="mt-3">
              <Col md={4}>
                <Form.Group controlId="formJoiningDate"className="mt-3">
                  <Form.Label>Joining Date</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.joiningDate}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        joiningDate: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formOfficialEmail"className="mt-3">
                  <Form.Label>Official Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.officialEmail}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        officialEmail: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formAnnualCTC"className="mt-3">
                  <Form.Label>Annual CTC</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.annualCTC}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        annualCTC: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            
            <Row className="mt-3">
              <Col md={4}>
                <Form.Group controlId="formPassword"className="mt-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={selectedEmployee.password}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        password: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formJobTitle"className="mt-3">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.jobTitle}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        jobTitle: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
             
            </Row>
            <h4>Bank Account Details</h4>
            <Row className="mt-3">
              <Col md={4}>
                <Form.Group controlId="formAccountNumber"className="mt-3">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.accountNumber}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        accountNumber: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formIfscCode"className="mt-3">
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.ifscCode}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        ifscCode: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formBranchName"className="mt-3">
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedEmployee.branchName}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        branchName: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <Form.Group controlId="formBranchAddress"className="mt-3">
                  <Form.Label>Branch Address</Form.Label>
                  <Form.Control
                    type="textarea"
                    value={selectedEmployee.branchAddress}
                    onChange={(e) =>
                      setSelectedEmployee({
                        ...selectedEmployee,
                        branchAddress: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleUpdateEmployee}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EmployeeTable;
