import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Card,
  Breadcrumb,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2"; // Import SweetAlert2

const JobApplicationForm = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [resumeFile, setResumeFile] = useState(null); // State to store the file

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Function to handle file input manually
  const handleFileChange = (e) => {
    setResumeFile(e.target.files[0]);
  };

  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000/api" 
    : "https://www.mayuhrsolutions.com/api";

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append basic form fields to the FormData
    formData.append("id", data.id);
    formData.append("data", JSON.stringify(data));
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("contact", data.contact);

    // Check if the file is uploaded before appending
    if (resumeFile) {
      formData.append("resume", resumeFile);
    }

    // Append additional fields to FormData
    formData.append("coverLetter", data.coverLetter);
    formData.append("experience", data.experience);
    formData.append("skills", data.skills);
    formData.append("education", data.education);
    formData.append("linkedin", data.linkedin);

    try {
      // Ensure the URL is a valid string by enclosing it in quotes
      const response = await fetch(`${apiUrl}/jobs/${jobId}/apply`, {
        method: "POST",
        body: formData,
      });

      // Check if the response is ok and handle accordingly
      if (response.ok) {
        localStorage.setItem("userEmail", data.email);
        Swal.fire({
          icon: "success",
          title: "Application Submitted!",
          text: "Your job application has been submitted successfully.",
        }).then(() => {
          // Navigate to another page after clicking OK on the success popup
          navigate("/dashboard"); // Change '/thank-you' to your desired route
        });

        reset(); // Reset the form after successful submission
        setResumeFile(null); // Reset file state
      } else {
        // Handle unsuccessful response
        const errorMessage = await response.text(); // Get the error message from the response
        Swal.fire({
          icon: "error",
          title: "Submission Failed",
          text:
            errorMessage ||
            "There was an error submitting your application. Please try again.",
        });
      }
    } catch (error) {
      // Handle network errors
      Swal.fire({
        icon: "error",
        title: "Network Error",
        text: "Could not submit the application. Please check your network connection.",
      });
      console.error("Submission Error:", error); // Log error for debugging
    }
  };

  const handleCancel = () => {
    reset(); // Reset the form fields
    setResumeFile(null); // Reset the file input
  };

  return (
    <Container>
      <h2 className="mt-3 registration-heading">Job Application Form</h2>
      <Breadcrumb>
        {/* <Breadcrumb.Item href="/candidate-dashboard">Home</Breadcrumb.Item> */}
        <Breadcrumb.Item href="/">Job Listings</Breadcrumb.Item>
        <Breadcrumb.Item active>Job Application</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="d-flex justify-content-center">
        <Col md={8}>
          <Card className="d-flex justify-content-center border-primary shadow-lg p-4">
            <Card.Body>
              <h3 className="text-center mt-4">Application Form</h3>
              <Form onSubmit={handleSubmit(onSubmit)} className="mb-5">
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="name">
                      <Form.Label>
                        Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="mt-3"
                        {...register("name", { required: "Name is required" })}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="email">
                      <Form.Label>
                        Email<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className="mt-3"
                        {...register("email", {
                          required: "Email is required",
                        })}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={6}>
                    <Form.Group controlId="contact">
                      <Form.Label>
                        Contact Number<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        className="mt-3"
                        {...register("contact", {
                          required: "Contact Number is required",
                          maxLength: { value: 10, message: "Max length is 10" },
                        })}
                        isInvalid={!!errors.contact}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.contact?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="resume">
                      <Form.Label>
                        Resume (PDF/Word)<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        className="mt-3"
                        onChange={handleFileChange} // Manually handle file change
                        isInvalid={!!errors.resume}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.resume?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={6}>
                    <Form.Group controlId="coverLetter">
                      <Form.Label>Cover Letter</Form.Label>
                      <Form.Control
                        as="textarea"
                        className="mt-3"
                        rows={3}
                        {...register("coverLetter")}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="experience">
                      <Form.Label>
                        Experience<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="mt-3"
                        {...register("experience", {
                          required: "Experience is required",
                        })}
                        isInvalid={!!errors.experience}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.experience?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={6}>
                    <Form.Group controlId="skills">
                      <Form.Label>Skills</Form.Label>
                      <Form.Control
                        type="text"
                        className="mt-3"
                        {...register("skills")}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="education">
                      <Form.Label>
                        Education<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="mt-3"
                        {...register("education", {
                          required: "Education is required",
                        })}
                        isInvalid={!!errors.education}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.education?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={6}>
                    <Form.Group controlId="linkedin">
                      <Form.Label>LinkedIn/GitHub Profile</Form.Label>
                      <Form.Control
                        type="text"
                        className="mt-3"
                        {...register("linkedin")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className=" mt-3">
                  <Button type="submit" className="me-3" variant="primary">
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    className="me-2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default JobApplicationForm;