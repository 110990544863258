import React, { useEffect, useState } from "react";
import { Table, Button, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CandidateDashboard = () => {
  const [applications, setApplications] = useState([]);
  console.log("applications", applications);
  const [user, setUser] = useState(null);
  const [showApplications, setShowApplications] = useState(false); // State to toggle applications table
  const navigate = useNavigate();

  // Check if the app is running on localhost (development)
  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000/api" // Local development
    : "https://www.mayuhrsolutions.com/api";
  // Fetch candidate's own job applications
  useEffect(() => {
    // if (showApplications) {
    const fetchApplications = async () => {
      const candidateEmail = localStorage.getItem("userEmail"); // Get email from localStorage

      if (!candidateEmail) {
        // Swal.fire({
        //   icon: "error",
        //   title: "First Apply Job",
        //   text: "Please log in or submit an application.",
        // });
        return;
      }

      try {
        const response = await fetch(
          `${apiUrl}/applications/candidate/${candidateEmail}`
        );
        const data = await response.json();
        console.log("data-------", data);
        if (response.ok) {
          setApplications(data);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to fetch applications.",
          });
        }
      } catch (error) {
        console.error("Error fetching applications:", error);
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "Could not fetch applications. Please check your network connection.",
        });
      }
    };

    fetchApplications();
    // }
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="registration-heading mb-4">Candidate Dashboard</h2>
      <h4 className="registration-heading mb-4">
        Welcome {applications[0]?.name}
      </h4>
      {/* Row for the cards */}
      <Row className="mb-4">
        <Col md={6}>
          <Card className="m-3 p-4 shadow">
            <Card.Body>
              <Card.Title>View Jobs</Card.Title>
              <Card.Text>
                Explore and apply for jobs that match your profile.
              </Card.Text>
              <Button variant="primary" onClick={() => navigate("/career")}>
                View Jobs
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="m-3 p-4 shadow">
            <Card.Body>
              <Card.Title>View Applications</Card.Title>
              <Card.Text>Review the status of your job applications.</Card.Text>
              <Button
                variant="success"
                onClick={() => setShowApplications(true)}
              >
                View Applications
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Conditionally render the table only when "showApplications" is true */}
      {showApplications && (
        <>
          <h2 className="registration-heading">Your Job Applications</h2>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Candidate Name</th>
                <th>Job ID</th>
                <th>Email</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              {applications.length === 0 ? (
                // Display message inside the tbody, right below the table header
                <tr>
                  <td colSpan="5" className="text-center">
                    No applications found.
                  </td>
                </tr>
              ) : (
                applications.map((application, index) => (
                  <tr key={application.id}>
                    <td>{index + 1}</td>
                    <td>{application.name}</td>
                    <td>{application.job_id}</td>
                    <td>{application.email}</td>
                    <td>{application.reasons}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default CandidateDashboard;
