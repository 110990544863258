import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Table,
  Spinner,
  Alert,
  Container,
  Modal,
  Button,
  Card,
  Form,
  Col,
  Row,
  Pagination,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const VisitorTable = () => {
  const [visitors, setVisitors] = useState([]);
  
  const perPage = 15; 
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    empId: "",
    date: null,
  });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
 

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  // Calculate the start and end index for slicing employees data based on currentPage
  const indexOfLastEmployee = currentPage * perPage;
  const indexOfFirstEmployee = indexOfLastEmployee - perPage;
  const currentEmployees = visitors.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(visitors.length / perPage);

  // Fetching visitors with filters applied
 
    const fetchVisitors = async () => {
      setLoading(true);
      setError("");
     
      try {
        const response = await axios.get(`${apiUrl}/visitors`, { params: filters });
        setVisitors(response.data.data || []);
      } catch (error) {
        console.error("Error fetching visitors:", error);
        setError("Failed to fetch visitors. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
    fetchVisitors();
  }, []);

  const handleFilterChange = (event) => {
    const { id, value } = event.target;

    setFilters((prevFilters) => ({
        ...prevFilters,
        [id]: id === "dateFilter" ? moment(value).format("YYYY-MM-DD") : value,
    }));
};


  const handleStaffIdClick = (visitor) => {
    setSelectedVisitor(visitor);
    setShowModal(true);
  };

  const handleResetFilters = () => {
    setFilters({
      empId: "",
      date:null,
    });
    fetchVisitors();
  };

  return (
    <Container className="mt-4">
      <div
        className="border shadow p-4"
        style={{ backgroundColor: "aliceblue" }}
      >
        <h1 className="registration-heading mt-3">Registration Enquiry</h1>
        <div className="d-flex justify-content-end ">
          <Button
            onClick={() => navigate("/hr-dashboard")}
            style={{ backgroundColor: "#f8701b" }}
          >
            Back to HR Dashboard
          </Button>
        </div>
        <Card className=" border shadow p-3 mt-3">
          <Row className="mt-3">
            <Col sm={12} md={6} lg={4}>
              {/* Filter Inputs */}
              <Form.Group controlId="empIdFilter">
                <Form.Label>Filter by Emp ID</Form.Label>
                <Form.Control
                  type="text"
                    id="empId"
                  value={filters.empId}
                  onChange={(e) =>
                    setFilters((prev) => ({ ...prev, empId: e.target.value }))
                  }
                  placeholder="Enter Employee ID"
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group controlId="dateFilter">
                <Form.Label>Filter by Date</Form.Label>
                <Form.Control
                  type="date"
                  id="dateFilter"
                  value={filters.date || ""}
                  onChange={(e) =>
                    setFilters((prev) => ({ ...prev, date: e.target.value }))
                  }
                />
              </Form.Group>
            </Col>
            <Col
              sm={12}
              md={12}
              lg={4}
              className="d-flex align-items-end justify-content-end"
            >
              <Button
                className="me-2"
                style={{ backgroundColor: "#f8701b" }}
                onClick={fetchVisitors}
              >
                Apply Filters
              </Button>
              <Button variant="secondary" onClick={handleResetFilters}>
                Reset Filters
              </Button>
            </Col>
          </Row>
        </Card>
        {loading && (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        )}

        {error && <Alert variant="danger">{error}</Alert>}

        {!loading && !error && (
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>ID</th>
                <th>Staff ID</th>
                <th>Client Name</th>
                <th>Contact Person</th>
                {/* <th>Mobile</th> */}
                {/* <th>Email</th>  */}
                {/* <th>Staff Name</th> */}
                {/* <th>Visiting Location</th> */}
                <th>Photo</th>
                <th>Submission Date</th>
              </tr>
            </thead>
            <tbody>
              {currentEmployees.map((visitor, index) => (
                <tr key={visitor.id}>
                  <td>{visitor.srNo || index + 1 + indexOfFirstEmployee}</td>
                  {/* <td>{visitor.id}</td> */}
                  <td>
                    <Button
                      variant="link"
                      onClick={() => handleStaffIdClick(visitor)}
                    >
                      {visitor.staff_employee_id}
                    </Button>
                  </td>
                  <td>{visitor.client_name}</td>
                  <td>{visitor.contact_person_name}</td>
                  {/* <td>{visitor.contact_person_mobile_no}</td> */}
                  {/* <td>{visitor.contact_person_email}</td> */}
                  {/* <td>{visitor.staff_employee_id}</td> */}
                  {/* <td>{visitor.staff_name}</td> */}
                  {/* <td>{visitor.visiting_location}</td> */}
                  <td>
                    {visitor.visitor_photo ? (
                      <a
                        // href={`${apiUrl}/downloads/${visitor.visitor_photo}`}
                        href={`http://localhost:5000/downloads/${visitor.visitor_photo}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Photo
                      </a>
                    ) : (
                      "No Photo"
                    )}
                  </td>
                  <td>
                    {new Date(visitor.submission_date)
                      ? moment(visitor.submission_date).format("DD-MMM-YYYY")
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <div className="d-flex justify-content-end">
        <Pagination>
  {/* Previous Button */}
  <Pagination.Prev
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  />

  {/* First Page */}
  {currentPage > 2 && (
    <>
      <Pagination.Item onClick={() => handlePageChange(1)}>
        1
      </Pagination.Item>
      {currentPage > 3 && <Pagination.Ellipsis />}
    </>
  )}

  {/* Current and Surrounding Pages */}
  {[...Array(totalPages)]
    .map((_, index) => index + 1)
    .filter(
      (page) =>
        page === currentPage || // Always show the current page
        page === currentPage - 1 || // One page before
        page === currentPage + 1 // One page after
    )
    .map((page) => (
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    ))}

  {/* Last Page */}
  {currentPage < totalPages - 1 && (
    <>
      {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
      <Pagination.Item onClick={() => handlePageChange(totalPages)}>
        {totalPages}
      </Pagination.Item>
    </>
  )}

  {/* Next Button */}
  <Pagination.Next
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  />
</Pagination>
        </div>

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          className="mt-3"
        >
          <Modal.Header closeButton>
            <Modal.Title className="registration-heading mt-3">
              Visitor Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedVisitor && (
              <div>
                <p>
                  <strong>ID:</strong> {selectedVisitor.id}
                </p>
                <p>
                  <strong>Client Name:</strong> {selectedVisitor.client_name}
                </p>
                <p>
                  <strong>Contact Person:</strong>{" "}
                  {selectedVisitor.contact_person_name}
                </p>
                <p>
                  <strong>Mobile:</strong>{" "}
                  {selectedVisitor.contact_person_mobile_no}
                </p>
                <p>
                  <strong>Email:</strong> {selectedVisitor.contact_person_email}
                </p>
                <p>
                  <strong>Staff ID:</strong> {selectedVisitor.staff_employee_id}
                </p>
                <p>
                  <strong>Staff Name:</strong> {selectedVisitor.staff_name}
                </p>
                <p>
                  <strong>Visiting Location:</strong>{" "}
                  {selectedVisitor.visiting_location}
                </p>
                <p>
                  <strong>Submission Date:</strong>{" "}
                  {selectedVisitor.submission_date
                    ? moment(selectedVisitor.submission_date).format(
                        "DD-MM-YYYY"
                      )
                    : "N/A"}
                </p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default VisitorTable;
