import React, { useState, useEffect } from "react";
import { Container, Table, Button, Pagination } from "react-bootstrap";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom"; 

const ApplicationsPage = () => {
  const { jobId } = useParams(); 
  const [applications, setApplications] = useState([]);
  const itemsPerPage = 5; 
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination logic
  const totalPages = Math.ceil(applications.length / itemsPerPage);
  const paginatedApplications = applications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Check if the app is running on localhost (development)
  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    // Fetch applications for the specific job
    const fetchApplications = async () => {
      try {
        const response = await fetch(`${apiUrl}/applications?jobId=${jobId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch applications");
        }
        const data = await response.json();
        setApplications(data);
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };
    fetchApplications();
  }, [jobId]); // Fetch applications when jobId changes

  const handleDeleteApplication = async (applicationId) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      try {
        const response = await fetch(
          `${apiUrl}/applications/${applicationId}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete application");
        }

        // Refresh application data after deletion
        const updatedApplications = applications.filter(
          (app) => app.id !== applicationId
        );
        setApplications(updatedApplications);

        // Show success message
        Swal.fire("Deleted!", "Your application has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting application:", error);

        // Show error message
        Swal.fire(
          "Error!",
          "There was a problem deleting the application. Please try again.",
          "error"
        );
      }
    }
  };

  return (
    <Container>
      <h3>Applications for Jobs</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Applicant Name</th>
            <th>Job Title</th>
            <th>Resume</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedApplications.map((app, index) => (
            <tr key={app.id}>
              <td>{index + 1}</td>
              <td>{app.name}</td>
              <td>{app.title}</td>
              <td>
                <a
                  href={`http://localhost:5000${app.resume}`} // Assuming the API returns the correct file path (e.g., /uploads/resume.pdf)
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Resume
                </a>
              </td>
              <td>
                {/* <Button 
                variant="success" 
                onClick={() => handleShortlistCandidate(app)} 
                className='me-3'
              >
                Shortlisted
              </Button> */}
                <Button
                  variant="danger"
                  onClick={() => handleDeleteApplication(app.id, "Rejected")}
                >
                  Rejected
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
  {/* Previous Button */}
  <Pagination.Prev
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  />

  {/* First Page */}
  {currentPage > 2 && (
    <>
      <Pagination.Item onClick={() => handlePageChange(1)}>
        1
      </Pagination.Item>
      {currentPage > 3 && <Pagination.Ellipsis />}
    </>
  )}

  {/* Current and Surrounding Pages */}
  {[...Array(totalPages)]
    .map((_, index) => index + 1)
    .filter(
      (page) =>
        page === currentPage || // Always show the current page
        page === currentPage - 1 || // One page before
        page === currentPage + 1 // One page after
    )
    .map((page) => (
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    ))}

  {/* Last Page */}
  {currentPage < totalPages - 1 && (
    <>
      {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
      <Pagination.Item onClick={() => handlePageChange(totalPages)}>
        {totalPages}
      </Pagination.Item>
    </>
  )}

  {/* Next Button */}
  <Pagination.Next
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  />
</Pagination>
    </Container>
  );
};

export default ApplicationsPage;
