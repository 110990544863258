import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Breadcrumb,
  Pagination,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AdminDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const itemsPerPage = 2;
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination logic
  const paginatedJobs = jobs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Pagination
  const totalPages = Math.ceil(jobs.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Check if the app is running on localhost (development)
  const isLocalhost = window.location.hostname === "localhost";

  // Set the API URL based on whether the app is running locally or in UAT
  const apiUrl = isLocalhost
    ? "http://localhost:5000/api" // Local development
    : "https://www.mayuhrsolutions.com/api";
  useEffect(() => {
    // Fetch job listings from the correct API endpoint
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${apiUrl}/jobs`);

        if (!response.ok) {
          throw new Error("Failed to fetch jobs");
        }

        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  const handleJobDelete = async (jobId) => {
    // Show a confirmation dialog before deletion
    const confirmDeletion = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmDeletion.isConfirmed) {
      try {
        // Proceed with the deletion
        await fetch(`${apiUrl}/jobs/${jobId}`, {
          method: "DELETE",
        });

        // Refresh job listings
        const response = await fetch(`${apiUrl}/jobs`);
        const data = await response.json();
        setJobs(data);

        // Show success message
        Swal.fire({
          title: "Deleted!",
          text: "Your job has been deleted.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(error);
        // Show error message
        Swal.fire({
          title: "Error!",
          text: "There was a problem deleting the job. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleShowApplications = (jobId) => {
    if (jobId) {
      navigate(`/applications/${jobId}`); // Pass the jobId as a URL parameter
    } else {
      console.error("Job ID is undefined or invalid");
    }
  };

  return (
    <Container>
      <h1 className="registration-heading mt-3">Admin Dashboard</h1>
      <Breadcrumb>
        <Breadcrumb.Item href="/hr-dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Admin Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      
      <>
        <h3 className="">Job Listings</h3>
        <Link to="/view-application" className="mt-3">
          <Button variant="primary">View Apllications</Button>
        </Link>
        <Link to="/add-job" className="d-flex justify-content-end">
          <Button variant="primary">Add New Job</Button>
        </Link>
        <div className="table-responsive">
        <Table className="mt-2 shadow" striped bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Company</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((job) => (
              <tr key={job.id}>
                <td>{job.title}</td>
                <td>{job.companyName}</td>
                <td>
                  <Link to={`/add-job/${job.id}`}>
                    <Button variant="warning" className="me-3">
                      Edit
                    </Button>
                  </Link>
                  <Button
                    variant="danger"
                    className="me-3"
                    onClick={() => handleJobDelete(job.id)}
                  >
                    Delete
                  </Button>
                 
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
      </>

      <Pagination className="d-flex justify-content-end">
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        {[...Array(totalPages).keys()].map((number) => (
          <Pagination.Item
            key={number}
            active={number + 1 === currentPage}
            onClick={() => handlePageChange(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </Pagination>
    </Container>
  );
};

export default AdminDashboard;
