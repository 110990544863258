import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Card,
  Button,
} from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { FaCalendarCheck } from "react-icons/fa";
import axios from "axios";

const EmployeeDashboard = () => {
  const { id } = useParams();
  const [loginTime, setLoginTime] = useState("");
  const [logoutTime, setLogoutTime] = useState("");
  const [progress, setProgress] = useState(0);
  const [employee, setEmployee] = useState(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [clockInStatus, setClockInStatus] = useState(false);
  const [hasClockedOut, setHasClockedOut] = useState(false); // Track if user has clocked out
  const navigate = useNavigate();

  const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    if (id) {
      localStorage.setItem("empId", id);
    }
    checkClockInStatus();
  }, [id]);

  const checkClockInStatus = () => {
    const lastLoginDate = localStorage.getItem("lastLoginDate");
    const now = moment();
    const eightThirtyAM = moment().set({
      hour: 8,
      minute: 30,
      second: 0,
      millisecond: 0,
    });

    if (lastLoginDate) {
      const lastLoginMoment = moment(lastLoginDate);

      if (now.isAfter(eightThirtyAM) && !now.isSame(lastLoginMoment, "day")) {
        setIsLoggedIn(false);
        setHasClockedOut(false);
        localStorage.removeItem("lastLoginDate");
        setLoginTime("");
        setLogoutTime("");
      } else {
        setLoginTime(localStorage.getItem("loginTime"));
        setLogoutTime(localStorage.getItem("logoutTime"));
        setHasClockedOut(!!localStorage.getItem("logoutTime"));
        setIsLoggedIn(!!localStorage.getItem("loginTime"));
      }
    }
  };

  const handleLogin = () => {
    const now = moment();
    const eightThirtyAM = moment().set({
      hour: 8,
      minute: 30,
      second: 0,
      millisecond: 0,
    });

    // Only store the login time if the user clocks in after 6 AM
    if (now.isAfter(eightThirtyAM)) {
      axios
        .post(`${apiUrl}/login-employee`, { empId: id })
        .then((response) => {
          const loginTime = moment().format("hh:mm A");
          setLoginTime(loginTime);
          setIsLoggedIn(true);

          // Store the login time in localStorage
          localStorage.setItem("loginTime", loginTime);
          localStorage.setItem("lastLoginDate", moment().format("YYYY-MM-DD"));

          Swal.fire(
            "Success",
            response.data.message || "Clocked in successfully!",
            "success"
          ).then(() => {
            // localStorage.removeItem("loginTime", loginTime);
            // localStorage.removeItem("logoutTime")
          });
        })
        .catch((error) => {
          console.error("Login failed:", error);
          Swal.fire("Error", "Login failed", "error");
        });
    } else {
      Swal.fire("Error", "You can only clock in after 6 AM", "error");
    }
  };

  const handleLogout = (isAutoLogout = false) => {
    const logoutMoment = isAutoLogout
      ? moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      : moment();
  
    // const logoutMoment = isAutoLogout
    //   ? moment().set({ hour: 0, minute: 5, second: 0, millisecond: 0 })
    //   : moment();
    // Convert moment object to a JavaScript Date object before sending to the backend
    const logoutTime = logoutMoment.toDate();
  
    axios
      .post(`${apiUrl}/logout`, { empId: id, isAutoLogout, logoutTime }) // Send the Date object
      .then((response) => {
        setLogoutTime(logoutMoment.format("hh:mm A"));
        setIsLoggedIn(false);
        setHasClockedOut(true);
  
        // Save logout time to localStorage
        localStorage.setItem("logoutTime", logoutMoment.format("hh:mm A"));
  
        // Calculate progress based on login and logout times
        const start = moment(loginTime, "hh:mm A");
        const elapsedMinutes = logoutMoment.diff(start, "minutes");
        const totalMinutes = 480; // 8 hours = 480 minutes
  
        const progressPercentage = Math.min(
          (elapsedMinutes / totalMinutes) * 100,
          100
        );
        setProgress(progressPercentage);
  
        Swal.fire(
          "Success",
          response.data.message ||
            (isAutoLogout ? "Auto-logged out successfully!" : "Clocked out successfully!"),
          "success"
        );
      })
      .catch((error) => {
        console.error("Logout failed:", error);
        Swal.fire("Error", "Logout failed", "error");
      });
  };
  

  const checkAutomaticLogout = () => {
    const now = moment();
    const elevenPM = moment().set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });

    // Trigger auto-logout only if the user is still logged in and it's past 8 PM
    if (now.isAfter(elevenPM) && isLoggedIn && !hasClockedOut) {
      handleLogout(true); // Auto-logout
    }
  };
  

  // Check for automatic logout every minute
  useEffect(() => {
    const interval = setInterval(() => {
      checkAutomaticLogout();
    }, 60000); // 60 seconds

    return () => clearInterval(interval); 
  }, [isLoggedIn, hasClockedOut]);

  const handleManualLogout = () => {
    handleLogout(false); // Manual logout
  };

  useEffect(() => {
    const updateProgress = () => {
      if (loginTime && !hasClockedOut) {
        const start = moment(loginTime, "hh:mm A");
        const now = moment();
        const totalMinutes = 480; // 8 hours = 480 minutes

        const elapsedMinutes = now.diff(start, "minutes");

        // Calculate progress as a percentage of the 8-hour workday
        const progressPercentage = Math.min(
          (elapsedMinutes / totalMinutes) * 100,
          100
        );
        setProgress(progressPercentage);

        if (elapsedMinutes >= totalMinutes) {
          clearInterval(interval);
        }
      }
    };

    const interval = setInterval(updateProgress, 1000);
    return () => clearInterval(interval);
  }, [loginTime, hasClockedOut]);

  const getProgressBarVariant = () => {
    if (progress < 50) return "warning";
    if (progress < 100) return "success";
    return "danger";
  };

  const handleCardClick = (path) => {
    const empId = localStorage.getItem("empId");
    if (empId) {
      navigate(`${path}/${empId}`);
    } else {
      Swal.fire("Error", "Employee ID not found", "error");
    }
  };

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/employee/${id}`);
        setEmployee(response.data.employee);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching employee details", error);
        // setLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  return (
    <Container className="mt-5">
    {/* Employee TimeSheet Section */}
    <div
        className="border shadow p-4"
        style={{ backgroundColor: "aliceblue" }}
      >
    <h1 className="registration-heading mt-3">Employee Dashboard</h1>
    <Row>
      <Col md={8}>
        <Card className="shadow">
          <Card.Header className="text-center bg-light">
            <h4 style={{ color: "rgb(145, 14, 14)" }}>Employee TimeSheet</h4>
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Col md={6} className="mb-3">
                <h6 className="fs-5 text-secondary">
                  Clock In Time: <span style={{ color: "rgb(145, 14, 14)" }}>{loginTime || "Not clocked in"}</span>
                  {!isLoggedIn && !hasClockedOut && (
                    <Button
                      style={{ backgroundColor: "#f8701b" }}
                      size="sm"
                      className="ms-3"
                      onClick={handleLogin}
                    >
                      Clock In
                    </Button>
                  )}
                </h6>
                <h6 className="fs-5 text-secondary mt-4">
                  Clock Out Time: <span style={{ color: "rgb(145, 14, 14)" }}>{logoutTime || "Not clocked out"}</span>
                  {isLoggedIn && !hasClockedOut && (
                    <Button
                      style={{ backgroundColor: "#f8701b" }}
                      size="sm"
                      className="ms-3"
                      onClick={handleManualLogout}
                    >
                      Clock Out
                    </Button>
                  )}
                </h6>
              </Col>
            </Row>
            <ProgressBar
              now={progress}
              variant={getProgressBarVariant()}
              label={`${Math.round(progress)}%`}
              style={{ height: "20px" }}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="shadow">
          <Card.Body>
            <h6 className="mb-3">
              <strong>Today's Date:</strong> {new Date().toLocaleDateString()}
            </h6>
            <h6 className="text-secondary">
              Department of Employee: <span style={{ color: "rgb(145, 14, 14)" }}>{employee?.department || "N/A"}</span>
            </h6>
            <h6 className="text-secondary mt-3">
              Reporting Manager: <span style={{ color: "rgb(145, 14, 14)" }}>{employee?.manager || "N/A"}</span>
            </h6>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  
    {/* Action Cards Section */}
    <Row className="mt-4">
      <Col md={4} className="mb-4">
        <Card
          onClick={() => handleCardClick("/employee-details")}
          className="text-white shadow-sm"
          style={{ backgroundColor: "#f8701b", cursor: "pointer" }}
        >
          <Card.Body className="d-flex align-items-center">
            <FaCalendarCheck size={30} className="me-3" />
            <Card.Title>My Profile</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} className="mb-4">
        <Card
          onClick={() => handleCardClick("/my-leaves")}
          className="text-white shadow-sm"
          style={{ backgroundColor: "#f8701b", cursor: "pointer" }}
        >
          <Card.Body className="d-flex align-items-center">
            <FaCalendarCheck size={30} className="me-3" />
            <Card.Title>My Leaves</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} className="mb-4">
        <Card
          onClick={() => handleCardClick("/enquiry-form")}
          className="text-white shadow-sm"
          style={{ backgroundColor: "#f8701b", cursor: "pointer" }}
        >
          <Card.Body className="d-flex align-items-center">
            <FaCalendarCheck size={30} className="me-3" />
            <Card.Title>Enquiry Form</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    </div>
  </Container>
  
  );
};

export default EmployeeDashboard;
