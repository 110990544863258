import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Card,
  Breadcrumb,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const OfferLetterForm = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [resumeFile, setResumeFile] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleFileChange = (e) => {
    setResumeFile(e.target.files[0]);
  };

  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost
    ? "http://localhost:5000/api"
    : "https://www.mayuhrsolutions.com/api";

  const onSubmit = async (data) => {
  const formData = new FormData();
  
  // Ensure jobId is included in the payload
  if (!jobId) {
    Swal.fire('Error', 'Job ID is missing.', 'error');
    return;
  }

  // Append all necessary fields to FormData
  formData.append("jobId", jobId);  // Send jobId correctly
  formData.append("title", data.title);
  formData.append("first_name", data.firstName);
  formData.append("middle_name", data.middleName || ""); // Optional
  formData.append("last_name", data.lastName);
  formData.append("email", data.email);
  formData.append("contact", data.contact);
  formData.append("position", data.position);
  formData.append("skills", data.skills || "");
  formData.append("experience", data.experience || "");
  formData.append("education", data.education);
  formData.append("cover_letter", data.coverLetter || "");
  formData.append("linkedin", data.linkedin || "");

  if (resumeFile) {
    formData.append("resume", resumeFile);
  }

  try {
    const response = await fetch(`${apiUrl}/offer-letters/${jobId}/apply`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      Swal.fire({
        icon: 'success',
        title: 'Application Submitted!',
        text: 'Your job application has been submitted successfully.',
      }).then(() => navigate("/dashboard"));
      reset();
      setResumeFile(null);
    } else {
      const errorMessage = await response.text();
      Swal.fire('Error', errorMessage || 'Failed to submit your application.', 'error');
    }
  } catch (error) {
    Swal.fire('Error', 'Network error occurred.', 'error');
    console.error('Submission Error:', error);
  }
};


  const handleCancel = () => {
    reset();
    setResumeFile(null);
  };

  return (
    <Container>
      <h2 className="registration-heading mt-3">Job Application Form</h2>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Job Listings</Breadcrumb.Item>
        <Breadcrumb.Item active>Job Application</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="d-flex justify-content-center ">
        <Col md={8}>
          <Card className="border-primary shadow-lg p-4 mb-3">
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={3}>
                    <Form.Group controlId="title">
                      <Form.Label>
                        Title <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        {...register("title", {
                          required: "Title is required",
                        })}
                      >
                        <option value="">Select</option>
                        <option value="Mr">Mr</option>
                        <option value="Ms">Ms</option>
                        <option value="Mrs">Mrs</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.title?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="firstName">
                      <Form.Label>
                        First Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        {...register("firstName", {
                          required: "First Name is required",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="middleName">
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control {...register("middleName")} />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="lastName">
                      <Form.Label>
                        Last Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        {...register("lastName", {
                          required: "Last Name is required",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={6}>
                    <Form.Group controlId="email">
                      <Form.Label>
                        Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        {...register("email", {
                          required: "Email is required",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="contact">
                      <Form.Label>
                        Contact Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...register("contact", {
                          required: "Contact Number is required",
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: "Enter a valid 10-digit number",
                          },
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.contact?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={6}>
                    <Form.Group controlId="position">
                      <Form.Label>
                        Position <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        {...register("position", {
                          required: "Position is required",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.position?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="resume">
                      <Form.Label>
                        Upload Resume (PDF/Word){" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={4}>
                    <Form.Group controlId="education">
                      <Form.Label>
                        Education <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        {...register("education", {
                          required: "Education is required",
                        })}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="skills">
                      <Form.Label>Skills</Form.Label>
                      <Form.Control {...register("skills")} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="experience">
                      <Form.Label>Experience</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        {...register("experience", {
                          required: "Experience is required",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.experience?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={6}>
                    <Form.Group controlId="coverLetter">
                      <Form.Label>Cover Letter</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        {...register("coverLetter")}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="linkedin">
                      <Form.Label>LinkedIn Profile</Form.Label>
                      <Form.Control {...register("linkedin")} />
                    </Form.Group>
                  </Col>
                </Row>

                <Button type="submit" className="mt-4" variant="primary">
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  className="mt-4 ms-2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OfferLetterForm;
