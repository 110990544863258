import React from "react";
// import axios from "axios";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  FaCalendarCheck,
  FaFileAlt,
  FaUserTie,
  FaChartLine,
} from "react-icons/fa";

const DashboardCards = () => {
  const navigate = useNavigate(); // Replacing useHistory with useNavigate

  const handleCardClick = (path) => {
    navigate(path); // Navigate to the selected path
  };

  // const [fullName, setFullName] = useState("");
  // const [error, setError] = useState("");
  // const [loading, setLoading] = useState(false);

  // Retrieve email from localStorage
  // const email = localStorage.getItem("email");

  // If email doesn't exist in localStorage, show an error message
  // useEffect(() => {
  //   if (!email) {
  //     setError('Email not found in localStorage. Please log in first.');
  //     return;
  //   }
  //   fetchFullName(email);
  // }, [email]);

  // const isLocalhost = window.location.hostname === "localhost";

  // const apiUrl = isLocalhost
  //   ? "http://localhost:5000/api"
  //   : 

  // Function to fetch fullName from backend
  // const fetchFullName = async (email) => {
  //   setLoading(true);
  //   setError('');
  //   setFullName('');

  //   try {
  //     // Send POST request to fetch fullName by email
  //     const response = await axios.post(`${apiUrl}/users/getFullName`, { email });
  //     console.log("response", response);
  //     if (response.data.fullName) {
  //       setFullName(response.data.fullName);
  //     } else {
  //       setError('User not found');
  //     }
  //   } catch (err) {
  //     setError('Error fetching data');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <div
      className="container mt-5 border shadow p-4 "
      style={{ backgroundColor: "#78b7f6" }}
    >
      {/* <div className="container">
      <h2>Get User Information</h2>

      {email && !loading && (
        <div>
          <h4>Email: {email}</h4>
          {fullName && <h4>Full Name: {fullName}</h4>}
        </div>
      )}

      {error && (
        <div className="mt-3 text-danger">
          <p>{error}</p>
        </div>
      )}

      {loading && <div className="mt-3">Loading...</div>}
    </div> */}
      <h3 className="text-center mb-4 text-dark">HR Dashboard</h3>
      <Row>
        <Col md={4} className="mb-4 ">
          <Card
            onClick={() => handleCardClick("/daily-attendance")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaCalendarCheck size={30} className="mr-3" />
              <Card.Title>Daily Attendance</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} className="mb-4">
          <Card
            onClick={() => handleCardClick("/payroll-sheet")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaFileAlt size={30} className="mr-3" />
              <Card.Title>Payroll Sheet</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} className="mb-4">
          <Card
            onClick={() => handleCardClick("/legal-documents")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaFileAlt size={30} className="mr-3" />
              <Card.Title>Legal Employee Documents</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} className="mb-4">
          <Card
            onClick={() => handleCardClick("/employee-profile")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaUserTie size={30} className="mr-3" />
              <Card.Title>Employee Profile</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} className="mb-4">
          <Card
            onClick={() => handleCardClick("/employee-info")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaUserTie size={30} className="mr-3" />
              <Card.Title>Employee Information</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} className="mb-4">
          <Card
            onClick={() => handleCardClick("/performance-report")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaChartLine size={30} className="mr-3" />
              <Card.Title>Performance Report</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} className="mb-4">
          <Card
            onClick={() => handleCardClick("/admin-dashboard")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaFileAlt size={30} className="mr-3" />
              <Card.Title>Job Listing</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} className="mb-4">
          <Card
            onClick={() => handleCardClick("/export-button")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaFileAlt size={30} className="mr-3" />
              <Card.Title>Download Excel Sheet</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card
            onClick={() => handleCardClick("/leaves")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaFileAlt size={30} className="mr-3" />
              <Card.Title>Employee Leaves</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card
            onClick={() => handleCardClick("/enquiry-info")}
            className="text-white"
            style={{ backgroundColor: "#f8701b" }}
          >
            <Card.Body className="d-flex align-items-center">
              <FaFileAlt size={30} className="mr-3" />
              <Card.Title>Enquiry Information</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardCards;
