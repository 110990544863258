import React, { useState, useEffect } from "react";
import { Container, Table, Button, Pagination } from "react-bootstrap";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom"; // For accessing jobId from URL

const ApplicationsPage = () => {
  const { jobId } = useParams(); // Get jobId from the URL
  console.log("======", jobId);
  const [applications, setApplications] = useState([]);
  console.log("app", applications);
  const itemsPerPage = 5; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination logic
  const totalPages = Math.ceil(applications.length / itemsPerPage);
  const paginatedApplications = applications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Check if the app is running on localhost (development)
  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000/api" // Local development
    : 'https://www.mayuhrsolutions.com/api';
  useEffect(() => {
    // Fetch applications for the specific job
    const fetchApplications = async () => {
      try {
        const response = await fetch(`${apiUrl}/applications?jobId=${jobId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch applications");
        }
        const data = await response.json();
        setApplications(data);
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };
    fetchApplications();
  }, [jobId]); // Fetch applications when jobId changes

  const handleDeleteApplication = async (applicationId) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      try {
        const response = await fetch(
          `${apiUrl}/applications/${applicationId}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete application");
        }

        // Refresh application data after deletion
        const updatedApplications = applications.filter(
          (app) => app.id !== applicationId
        );
        setApplications(updatedApplications);

        // Show success message
        Swal.fire("Deleted!", "Your application has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting application:", error);

        // Show error message
        Swal.fire(
          "Error!",
          "There was a problem deleting the application. Please try again.",
          "error"
        );
      }
    }
  };

  return (
    <Container>
      <h3>Applications for Jobs</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Applicant Name</th>
            <th>Job Title</th>
            <th>Resume</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {applications.map((app, index) => (
            <tr key={app.id}>
              <td>{index + 1}</td>
              <td>{app.name}</td>
              <td>{app.title}</td>
              <td>
                <a
                  href={`http://localhost:5000${app.resume}`} // Assuming the API returns the correct file path (e.g., /uploads/resume.pdf)
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Resume
                </a>
              </td>
              <td>
                {/* <Button 
                variant="success" 
                onClick={() => handleShortlistCandidate(app)} 
                className='me-3'
              >
                Shortlisted
              </Button> */}
                <Button
                  variant="danger"
                  onClick={() => handleDeleteApplication(app.id, "Rejected")}
                >
                  Rejected
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination className="d-flex justify-content-end">
        <Pagination.Prev
          onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
        />
        {[...Array(totalPages).keys()].map((number) => (
          <Pagination.Item
            key={number}
            active={number + 1 === currentPage}
            onClick={() => setCurrentPage(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() =>
            currentPage < totalPages && setCurrentPage(currentPage + 1)
          }
        />
      </Pagination>
    </Container>
  );
};

export default ApplicationsPage;
