import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Container, Button, Row, Col, InputGroup } from "react-bootstrap";
import { FaUser, FaLock } from "react-icons/fa";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


const LoginPage = () => {
  const navigate = useNavigate();
  const initialValues = {
    empId: "",
    password: "",
  };
  const validationSchema = Yup.object({
    empId: Yup.string()
      .required("Employee ID is required")
      .min(4, "Employee ID must be at least 4 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const logoutThreshold = 24 * 60 * 60 * 1000; 
  // const logoutThreshold = 10 * 60 * 1000; 

const apiUrl = window.location.hostname === "localhost"
  ? "http://localhost:5000/api"
  : window.location.hostname === "3.108.171.148"
  ? "http://3.108.171.148:5000/api"
  : "https://www.mayuhrsolutions.com/api";

  const handleSubmit = async (values, { setSubmitting }) => {
   
    try {
      const response = await axios.post(`${apiUrl}/login`, values);
      const loginTimeFirst = Date.now();
      localStorage.setItem("loginTimeFirst", loginTimeFirst); // Store login time in local storage
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("empId", values.empId);
      localStorage.setItem("role", "Employee");
      Swal.fire("Success", "Login successful", "success");
      navigate(`/employee-dashboard/${values.empId}`);
    } catch (error) {
      console.error("Login Error:", error.response?.data || error.message);
      Swal.fire("Error", "Invalid credentials", "error");
    } finally {
      setSubmitting(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("empId");
    localStorage.removeItem("loginTime");
    localStorage.removeItem("logoutTime");
    localStorage.removeItem("loginTimeFirst");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    // setUserRole(null);
    // setIsLoggedIn(false);

    Swal.fire("Logged Out", "You have been logged out successfully.", "success");
    navigate("/");
    window.location.reload();
  };

  const handleAutoLogout = () => {
    Swal.fire(
      "Session Expired",
      "Your session has expired due to inactivity. Please log in again.",
      "warning"
    ).then(() => {
      handleLogout();
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
   
    const loginTimeFirst = localStorage.getItem("loginTimeFirst");

    if (token) {
      // setIsLoggedIn(true);

     

      // Check if the user session has expired
      if (loginTimeFirst) {
        const timeSinceLogin = Date.now() - parseInt(loginTimeFirst, 10);
        if (timeSinceLogin >= logoutThreshold) {
          handleAutoLogout();
        }
      }
    } else {
      // setIsLoggedIn(false);
    }
  }, [navigate]);

  return (
    <Container fluid className="background-image-container ">
      <Row className=" ">
        <Col lg={12}>
          <div className="bg-white mb-3 border shadow p-4">
            <h2 className="registration-heading mt-3">Employee Login</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                    <Field
                      name="empId"
                      type="text"
                      placeholder="Enter your Employee ID"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="empId"
                      component="div"
                      className="invalid-feedback"
                    />
                  </InputGroup>

                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                    <Field
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </InputGroup>
                  <p className="text-center mt-3">
                    <Link to="/forgot-password-employee">Forgot Password?</Link>
                  </p>
                  <div className="d-flex justify-content-center">
                    <Button
                      // variant="primary"
                      style={{ backgroundColor: "#f8701b" }}
                      type="submit"
                      className="mt-3"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Logging in..." : "Login"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
