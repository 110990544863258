import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Container,
  Button,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import { FaUser, FaLock } from "react-icons/fa";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const jobId = location.state?.jobId;
  
  // Initial values for the form
  const initialValues = {
    email: "",
    password: "",
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000/api" 
    : 'https://www.mayuhrsolutions.com/api';

  // Handle form submission
  const handleSubmit = (values, { resetForm }) => {
    console.log("Attempting login with email:", values.email, "and password:", values.password);
  
    axios
      .post(`${apiUrl}/login`, {
        email: values.email.trim(),
        password: values.password,
      })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          // Display success alert
          Swal.fire({
            icon: 'success',
            title: 'Login Successful',
            text: 'You will be redirected shortly!',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.setItem("token", response.data.token); 
              localStorage.setItem('role', "Candidate");
              // Redirect to the home page after the user clicks "OK"
              navigate('dashboard');
              window.location.reload();
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error in login:", error); // Log error
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: "Error logging in. Please try again.",
        });
      });
  
    resetForm();
  };

  return (
    <Container fluid className="background-image-container">
      {/* <h2 className="text-center mt-3" style={{ color: "#8e44ad" }}>Candidate Login</h2> */}
      <Row className="">
        <Col lg={12}>
          <div className="bg-white mb-3 border shadow p-4">
          <h2 className="registration-heading text-center mb-4">Candidate Login</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      className={`form-control ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                    <Field
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                      className={`form-control ${
                        touched.password && errors.password ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </InputGroup>
                  <p className="text-center mt-3">
                    <Link to="/candidate-forgot-password">Forgot Password?</Link>
                  </p>
                  <div className="d-flex justify-content-center">
                    <Button type="submit" className="mt-3" style={{backgroundColor:"#f8701b"}}>
                      Login
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <p className="text-center mt-3">
              Not registered? <Link to="/registration">Register Here</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
